import React from 'react';
import {useState, useEffect, useRef} from "react";
import axios from "axios";
import MoreInfo from "./MoreInfo";
import {Link} from "react-router-dom";
import {  encrypt, decrypt  } from "../Security";
// import TicketCardSkeleton from '../SkeletonLoadingComponent/TicketCardSkeleton'



function TicketCard({fromCountry,airlines,day,day2,stops, toCountry,fromCode, toCode,departure, arrival,duration,price,route,token, leavingDate, returningDate,key}) {

    const currency = localStorage.getItem('Currency');
    if (currency === 'NGN') {
        price = Number(price).toLocaleString('en-NG', { maximumFractionDigits: 0 });
        price = `₦${price}`;
    } else if (currency === 'USD') {
        price = Number(price).toLocaleString('en-US', { maximumFractionDigits: 0 });
        price = `$${price}`;
    } else if (currency === 'EUR') {
        price = Number(price).toLocaleString('en-GB', { maximumFractionDigits: 0 });
        price = `€${price}`;
    }
    const [showMoreInfo, setshowMoreInfo] = useState(false);

    const hideInfo = () =>{
        setshowMoreInfo(!showMoreInfo)
    }
    const showInfo = () =>{
        setshowMoreInfo(!showMoreInfo)
    }


    const divRef = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setshowMoreInfo(!showMoreInfo);

            }

        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showMoreInfo]);



    function setLocal(){
        localStorage.setItem('duration', duration);
        localStorage.setItem('day2', day2);
        localStorage.setItem('day', day);
        localStorage.setItem('departure', departure);
        localStorage.setItem('fromCountry', fromCountry);
        localStorage.setItem('fromCode', fromCode);
        localStorage.setItem('toCode', toCode);
        localStorage.setItem('toCountry', toCountry);
        localStorage.setItem('arrival', arrival);
        localStorage.setItem('stops', stops);
        localStorage.setItem('route', JSON.stringify(route));
        localStorage.setItem('bookingToken', encrypt(String(token)));
            }


    return (
    <div>
        {showMoreInfo &&
            <MoreInfo
             key={key}
            duration={duration}
            route={route}
            hideinfo={hideInfo}
        />

        }

        <div className='flightTicket' key={key}>
            <div className="flightCover">
                <div className='flightDetails' onClick={showInfo}>
                    <div className='detailCover'>
                        <div className='flightDate'>{day}</div>
                        <div className='actualDetails'>
                            <img className='fromTo'
                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687836109/Group_133542_usyaf7.svg'/>
                            <div>
                                <div className='fromLocation' id='fromLocation' >
                                    {departure} {fromCountry} <span>{fromCode}</span>
                                </div>
                                <div id='fromLocation' className='flightBox'>
                                    <div className='timeSpent'>
                                        {duration}
                                    </div>
                                    {airlines.map(img =>(
                                    <img className='airImg'
                                         key={img.id}
                                         src={img} />
                                        ))}
                                    <div className='noOfStops'>
                                        {stops} {stops === 1 ? 'stop' : 'stops'}
                                    </div>

                                </div>
                                <div className='fromLocation'>
                                    {arrival} {toCountry} <span>{toCode}</span>
                                </div>
                            </div>
                        </div>
                        <div className='flightDate'>{day2}</div>
                    </div>
                    <div className='moreDetails'>
                        <img
                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687844506/info-circle_xzimrn.svg'/>
                        <div>More Flight Info</div>
                    </div>

                </div>
                <div className='ticketCase'>
                    <div className='ticketPricing'>
                        <div className='price'>
                            {price}
                        </div>

                        <Link to='/bookingpage'>
                            {
                                <button onClick={setLocal}>
                                    Book Flight
                                </button>
                            }

                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>


    );
}

export default TicketCard;
