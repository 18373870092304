import axios from "axios";
import { useState, useEffect } from "react";
import envVariables from "../../dev";

function CountrySelector({ imageSrc, countrySelect, firstText, secondText = "Enter City or Airport", choice, setShowList, onclick }) {
    const [selectedCountry, setSelectedCountry] = useState(() => {
        const storedCountry = localStorage.getItem(countrySelect);
        return storedCountry ? JSON.parse(storedCountry)[1] : "Lagos";
    });
    const [recentlySelectedCountries, setRecentlySelectedCountries] = useState(() => {
        const storedCountries = localStorage.getItem("recentlySelectedCountries");
        return storedCountries ? JSON.parse(storedCountries) : [];
    });
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const storedCountries = localStorage.getItem("recentlySelectedCountries");
        if (storedCountries) {
            setRecentlySelectedCountries(JSON.parse(storedCountries));
        }

        fetchCountries(); // Fetch countries initially
    }, []);

    useEffect(() => {
        localStorage.setItem(
            "recentlySelectedCountries",
            JSON.stringify(recentlySelectedCountries)
        );
    }, [recentlySelectedCountries]);

    const getAPIKEY =()=>{
        const trip = localStorage.getItem('Trip')||'One Way'
        let apikey
        if (trip === 'One Way'){
            apikey = envVariables.APIKEY_RETURN_ONEWAY;
        }
        else{
            apikey = envVariables.APIKEY_MULTICITY;
        }
        return apikey
    }


    const fetchCountries = () => {
        axios
            .get("https://api.tequila.kiwi.com/locations/query", {
                params: {
                    term: selectedCountry,
                    locale: "en-US",
                    location_types: "airport",
                    limit: 10,
                    active_only: true
                },
                headers: {
                    "accept": "application/json",
                    "apikey": getAPIKEY()
                }
            })
            .then((response) => {
                const countriesData = response.data.locations.map((location) => ({
                    name: location.city.name,
                    code: location.city.code,
                    airport: location.name,
                    country: location.city.country.name,
                }));
                setCountries(countriesData);
            })
            .catch((error) => {
                console.error("Error fetching countries:", error);
            });
    };

    const handleCountrySelect = (event) => {
        const country = event.target.textContent.split(',')[0].trim();
        const combinedCountries = [...countries, ...recentlySelectedCountries];
        const selectedCountryObj = combinedCountries.find((c) => c.name === country);
        const countryCode = selectedCountryObj.code;
        const countryAir = selectedCountryObj.airport;
        const countryName = selectedCountryObj.country;

        setSelectedCountry(countryAir); // Set the selected value first
        if (!recentlySelectedCountries.some((c) => c.airport === countryAir)) {
            // Create a new array with the selected country as the first element
            const newRecentlySelectedCountries = [
                { airport: countryAir, country: countryName, name: country, code: countryCode },
                ...recentlySelectedCountries.slice(0, 1)
            ];

            setRecentlySelectedCountries(newRecentlySelectedCountries);
        }

        const countrySelectList = [countryCode, countryAir];
        localStorage.setItem(countrySelect, JSON.stringify(countrySelectList));
        setShowList(false);
    };

    const handleInputChange = (event) => {
        const inputText = event.target.value.trim();
        setSelectedCountry(inputText);
        fetchCountries();
        choice = true;
    };

    return (
        <div className="c-but" onClick={onclick}>
            <div className="from" onClick={() => setShowList(!choice)}>
                <img src={imageSrc} alt="country" />
                <div className="sec">
                    {firstText}
                    <br />
                    <input
                        className='killTimi'
                        type="text"
                        placeholder={secondText}
                        onChange={handleInputChange}
                        value={selectedCountry}
                    />
                </div>
            </div>
            {choice && (
                <div className="countries" id='countries'>
                    <>
                        <div className="recent">RECENT PLACES</div>
                        <ul>
                            {recentlySelectedCountries.map((country) => (
                                <li className='countryList' key={country.name} onClick={handleCountrySelect}>
                                    <img className='serchImg' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687746430/ri_building-line_chv1xe.svg'/>{country.name}, {country.country}<br/><img className='serchImg' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687747734/mdi_airplane_lmshrw.svg'/>{country.airport}
                                </li>
                            ))}
                        </ul>
                        <div className="recent">CITIES</div>
                        <ul>
                            {countries.map((country) => (
                                <li className='countryList' key={country.name} onClick={handleCountrySelect}>
                                    <img className='serchImg' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687746430/ri_building-line_chv1xe.svg'/>{country.name}, {country.country}<br/><img className='serchImg' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687747734/mdi_airplane_lmshrw.svg'/>{country.airport}
                                </li>
                            ))}
                        </ul>
                    </>
                </div>
            )}
        </div>
    );
}

export default CountrySelector;
