import React, {useEffect, useState} from "react";

function NumberCounter({ defaultValue, step, name, updateValue,valueStopper, adult, infant }) {
    const [value, setValue] = useState(defaultValue);

    const handleIncrement = () => {
        if(valueStopper < 9) {
            if(adult > infant){
                setValue(value + step);
            }
        }
    };

    const handleDecrement = () => {
        if(value > 0){
            setValue(value - step);
        }
    };

    useEffect(() => {
        updateValue(name, value);
    }, [value]);

    return (
        <div>
            <button onClick={handleDecrement}>-</button>
            <span id='addSubtract-span'>{value}</span>
            <button id='add' onClick={handleIncrement}>+</button>
        </div>
    );
}
export default NumberCounter;