import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"
import {Link, useLocation} from "react-router-dom";
import {useRef} from "react";

export default function Header(){

    const [selectedItem, setSelectedItem] = useState(null);
    const [isTrue, setIsTrue] = useState(() => false)
    const location = useLocation();
    const [showFirst, setShowFirst] = useState(true);
    const [showSecond, setShowSecond] = useState(false);

    useEffect(() =>{
            if (location.pathname === '/visa') {
                setIsTrue(true);
            } else if (location.pathname === '/services') {// when support page is routed it will be fixed
                setIsTrue(true);
            } else if (location.pathname === '/Support'){
                setIsTrue(true);
            }else if (location.pathname === '/AboutUs') {
                setIsTrue(true);
            } else {
                setSelectedItem(false);
            }
        },
        [isTrue]
    )

    useEffect(() => {
        // Update selectedItem state when the location changes
        if (location.pathname === '/') {
            setSelectedItem('item1');
        }
        else if (location.pathname === '/visa') {
            setSelectedItem('item2');
        } else if (location.pathname === '/services') {// when support page is routed it will be fixed
            setSelectedItem('item3');
            alert('Page Under Construction');
        } else if (location.pathname === '/Support'){
            setSelectedItem('item4');
        }else if (location.pathname === '/AboutUs') {
            setSelectedItem('item5');
        } else {
            setSelectedItem(null);
        }
    }, [location]);

    function handleItemClick(item) {

        if (location.pathname === '/services') {// when support page is routed it will be fixed
            alert('Page Under Construction');
        }
        setSelectedItem(item);

    }
    const [showDiv, setShowDiv] = useState(false);
    const divRef = useRef(null);
    const divRef2 = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setShowDiv(!showDiv);
                divRef2.current.click();

            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showDiv]);

    const handleClickFirst = () => {
        setShowFirst(false);
        setShowSecond(true);
        setShowDiv(!showDiv);
    };

    const handleDivClick = () => {
        setShowFirst(true);
        setShowSecond(false);
        setShowDiv(showDiv);
        console.log("testing", !showDiv)
    };
    const SignUp = () => {

        window.location.href = '/signup';

    };

    return(
        <div>
            <div className='fixWidth'>
                <nav>
                    <Link to='/'>
                        <LazyLoadImage effect = "blur" className='logo' src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1683163940/nakedjarome_1_xfopye.svg"/>
                    </Link>
                    <div className='nav_d'>
                        <section className="p-menu1">
                            <div id="navbar" className="navigation" role="navigation">
                                <input id="toggle1" type="checkbox"/>
                                <label className="hamburger1" htmlFor="toggle1">
                                    {showFirst && (
                                    <div className="top" onClick={handleClickFirst}></div>
                                    )}
                                    {showSecond && (
                                    <div className="below" ref={divRef2} onClick={handleDivClick}></div>
                                    )}
                                </label>
                                {showDiv &&
                                    <div className="menu1" ref={divRef}>

                                        <a className="link1" href="">Flight</a>
                                        <a className="link1" href="">Visa</a>
                                        <Link to='/ourservices'  className="link1">
                                            <a className="link1" href="">Our Service</a>
                                        </Link>
                                        <Link to='/Support' className="link1">
                                            <a className="link1" href="">Support</a>
                                        </Link>

                                        <Link to='/AboutUs' className="link1">
                                            <a className="link1" href="">About Us</a>
                                        </Link>
                                        {/*<a className='bot'>NGN<LazyLoadImage effect = "blur" onClick={handleShowButton} className='dd' src={arr}/></a>*/}
                                        {/*{showDiv && <div><button className="buttonBlue menuSize">Sign up</button></div>}*/}

                                        {/*<a className="link1" href="">About Us</a>*/}
                                        {/*<a className='bot'>NGN<LazyLoadImage effect = "blur" onClick={handleShowButton} className='dd' src={arr}/></a>*/}
                                        {/*{showDiv && */}
                                        <div>
                                            <button onClick={SignUp} className="buttonBlue menuSize">Sign up</button>
                                        </div>
                                        {/*}*/}

                                        {/*</Link>*/}
                                        {/*    <div><button className="buttonBlue menuSize">Sign up</button></div>*/}
                                    </div>
                                }
                            </div>
                        </section>
                        <div className='hideAndSeek'>
                            <div className='navContainer'>
                                <Link to='/'  className='link2'>
                                <div className={selectedItem === 'item1' ? 'clickedActive' : ''}
                                     onClick= {() => handleItemClick('item1')}>Flight</div>
                                </Link>
                                <Link to='/visa'  className='link2'>
                                <div
                                    className={selectedItem === 'item2' ? 'clickedActive' : ''}
                                    onClick={() => handleItemClick('item2')} >Visa</div>
                                </Link>
                                <Link to='/ourservices'  className='link2' >
                                <div
                                    className={selectedItem === 'item3' ? 'clickedActive' : ''}
                                    onClick={() => handleItemClick('item3')}  >Our services</div>
                                </Link>
                                <Link to='/Support'  className='link2'>
                                <div
                                      className={selectedItem === 'item4' ? 'clickedActive' : ''}
                                      onClick={() => handleItemClick('item4')} >Support</div>
                                </Link>
                                <Link to='/AboutUs'  className='link2'>
                                <div className={selectedItem === 'item5' ? 'clickedActive' : ''}
                                     onClick={() => handleItemClick('item5')} >About Us</div>
                                </Link>
                                {/*<div>*/}
                                    {/*<div>NGN*/}
                                    {/*    <LazyLoadImage effect = "blur" onClick={handleShowButton} className='dd' src={arr}/>*/}
                                    {/*</div>*/}
                                    {/*{showDiv && <div><button className="buttonBlue menuSize">Sign up</button></div>}*/}
                                {/*</div>*/}

                                <Link to='/managebookingpage'  className='link2'><div className='navControl'>Manage your Booking</div></Link>
                                <div>
                                    <button className='nav_b'>Get Started</button>
                                </div>

                            </div>


                        </div>


                    </div>
                </nav>
            </div>
        </div>
)

}