import React, {useEffect, useState} from 'react';
import BlueBack from "../SignUp/BlueBack";
import {Link} from "react-router-dom";
import InputField from "../InputField/InputField";
import lock from "../../images/lock.png"
import axiosWithAuth from "../Axios/axiosWithAuth";
import axios from "axios";
import sma from "../../images/sms.png";
import envVariables from "../../dev";
import Spinner from "../Spinner/Spinner";

function PasswordResetEmail() {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const apikey = envVariables.APIKEY;

        try {
            const response = await axiosWithAuth(apikey).post('/user/password-reset/', {
                email,
            });

            if (response.status === 200 || response.status === 201) {
                const ui = response.data.uidb64;
                const tok = response.data.token;
                window.location.href = `#/password-reset/${ui}/${tok}`;
                setErrorMessage(response.data.detail);
            } else {
                setErrorMessage('Invalid credentials. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
        finally{
            setIsLoading(false)
        }
    };

    return (
        <div>
            <BlueBack id='PasswordEmail' containerClassName="PasswordReset">

                    <Link to="/signup">
                        <img className='cancelButton'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png'
                             alt='cancelButton'/>
                    </Link>
                    <div id='passRight' className='img-con'>
                        <img id="passkey"
                             src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1679567393/Frame_3325_1_otpbkn.svg"/>
                    </div>
                    <div className='first' id='wrd-set'>
                        Trouble logging in?
                    </div>
                    <div id='passRight_' className='secx'>Enter your email, we’ll send you a  <br/>link to get back into your account.</div>
                <InputField classNameSg='sg sgx' value={email}onChange={(event) => setEmail(event.target.value)} className="col-9 fi"
                            name="Email" type="email" placeholder="Email" required iconSrc={sma}/>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <button onClick={handleSubmit} type='submit' id="passEmailButton"  className='stepButton'>{isLoading? <Spinner/> : 'Send email'}
                    </button>
                </BlueBack>
        </div>
    );
}

export default PasswordResetEmail;
