import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import './payment.css'
import {decrypt} from "../Security";
import envVariables from "../../dev";
import axiosWithAuth from "../Axios/axiosWithAuth";


const PaystackComponent = ({currSign, stringMainPrice, PrevSeatPage}) => {
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState(0);
    const [paymentpg, setPaymentpg] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [paymentCode, setPaymentcode] = useState('');
    const [name, setName] = useState('');
    const [Image, setImage] = useState(null);
    const [error, setError] = useState(null);
    const [file, setFile] = useState("https://res.cloudinary.com/dk80ynx1w/image/upload/v1678524294/Group_133533_mje81f.svg");
    const fileInputRef = useRef(null);
    const [payLoading, setPayLoading]=useState(false);
    const [popup, setPopUp]=useState(false);
    const [payLoading1, setPayLoading1]=useState(false);
    const [errmsg, setErrmsg]=useState(false);
    const [errImg, setErrImg]=useState('https://res.cloudinary.com/do5wu6ikf/image/upload/v1702572525/starpenzu/Check_bi7fsn.svg')

    const handleFileChange = (event) => {
        const f =event.target.files[0];
        setFile(f);
        setImage(URL.createObjectURL(f))
        set_to_local(file, 'myRecipt');
    };


    const get_from_local = (key_name) =>{
        const data_stringify = localStorage.getItem(`${key_name}`);
        if(data_stringify){
            const full_response = JSON.parse(data_stringify)
            // console.log(`${key_name},found in local storage`)
            return full_response
        }else{
            // console.log(`${key_name}, not found in local storage`)
        }
    }
    const apikey = envVariables.APIKEY;

    const fetchData = async () => {

        //pass customer data for first passenger
        localStorage.removeItem('tokenize_response')
        localStorage.removeItem('save_booking_response')
        const email = localStorage.getItem('EmailBookingPage0') ? localStorage.getItem('EmailBookingPage0') : '';
        const name = localStorage.getItem('SurnameBookingPage0') && localStorage.getItem('FirstNamebookingPage0') ? localStorage.getItem('SurnameBookingPage0') + localStorage.getItem('FirstNamebookingPage0'): '';
        const passport_number = localStorage.getItem('PassportNumberBookingPage0')  ?  localStorage.getItem('PassportNumberBookingPage0'): '';
        const adults = localStorage.getItem('adults')  ?  localStorage.getItem('adults'): '';
        const infant = localStorage.getItem('infant')  ?  localStorage.getItem('infant'): '';
        const children = localStorage.getItem('children')  ?  localStorage.getItem('children'): '';
        const base_fare = localStorage.getItem('base_fare')  ?  localStorage.getItem('base_fare'): '';
        const Total_price = localStorage.getItem('base_fare')  ?  localStorage.getItem('base_fare'): '';
        const service_charge = localStorage.getItem('service_charge')  ?  localStorage.getItem('service_charge'): '';
        const baggage = get_from_local('baggage')  ?  get_from_local('baggage') : null;
        const seat_map = get_from_local('seat_map')  ?  get_from_local('seat_map') : null;
        const quick_option = get_from_local('quick_option')  ?  get_from_local('quick_option') : null;
        const ticket_type = get_from_local('ticket_type')  ?  get_from_local('ticket_type') : null;
        const ticket_type_price = get_from_local('ticket_type_price')  ?  get_from_local('ticket_type_price') : null;
        const Insurance_price = get_from_local( 'insurancePerPassangers0')  ?  get_from_local('insurancePerPassangers0') : null;
        const Garantee_price = get_from_local('FlightGuarantee')  ?  get_from_local('FlightGuarantee') : null;


        const booking_summary = {
                    "adults": adults,
                    "infant": infant,
                    "children": children,
                    "base_fare": base_fare,
                    "service_charge": service_charge,
                    "Total_price": Total_price,
                    "baggages": baggage,
                    "seat_map": seat_map,
                    "quick_option": quick_option,
                    "ticket_type": ticket_type,
                    "ticket_type_price": ticket_type_price,
                    "Insurance_price": Insurance_price,
                    "Garantee_price": Garantee_price,
                };
        console.log(booking_summary, 'bbk')
        setPayLoading(true)
        try {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('name', name);
            formData.append('passport_number', passport_number);
            formData.append('image', file);
            formData.append('booking_summary', booking_summary);
            const response = await axiosWithAuth(apikey).post('/payment/generate_payment_code/', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.data === 'Successful') {
                save_booking()
                setPayLoading(false)
                setPopUp(true)
                console.log(response.data);
                // setInterval(setPopUp(false), 3000)
            }
        } catch (error) {
            setPayLoading(false)
            console.error('Error fetching data:', error);
        }
    };




    const getPassengers =()=>{
        const passData = JSON.parse(localStorage.getItem('passBookingList'))
        return passData
    }
    const numCustomers = localStorage.getItem('passengerIndex')
    const bookingResponse = JSON.parse(localStorage.getItem('booking_response_data'));
    const handBagCount = bookingResponse.baggage.definitions.hand_bag.length
    const holdBagCount = bookingResponse.baggage.definitions.hold_bag.length
    const getBaggage =()=>{
        let passengerList = [];

        for (let i = 0; i < numCustomers; i++) {
            let indices_ =[];
            let indices__ =[];
            let passengergrp =[];
            let passengergrp_ =[];
            let price = {
                currency: localStorage.getItem('Currency'),
            }
            let price_ = {
                currency: localStorage.getItem('Currency'),
            }
            for (let f = 0; f < handBagCount; f++) {
                let handbagIndexPassenger = localStorage.getItem(`bagHandIndex${f}_passenger${i}`)
                if (handbagIndexPassenger !== null) {
                    indices__.push(handbagIndexPassenger);
                    passengergrp_ =bookingResponse.baggage.definitions.hand_bag[handbagIndexPassenger].conditions.passenger_groups
                    let rawAmount_ = bookingResponse.baggage.definitions.hand_bag[handbagIndexPassenger].price.amount * localStorage.getItem('rate');
                    let rawBase_ = bookingResponse.baggage.definitions.hand_bag[handbagIndexPassenger].price.base * localStorage.getItem('rate');
                    let rawService_ = bookingResponse.baggage.definitions.hand_bag[handbagIndexPassenger].price.service * localStorage.getItem('rate');
                    let rawService_flat_ = bookingResponse.baggage.definitions.hand_bag[handbagIndexPassenger].price.service_flat * localStorage.getItem('rate');
                    let rawMerchant_ = bookingResponse.baggage.definitions.hand_bag[handbagIndexPassenger].price.merchant * localStorage.getItem('rate');
                    price_['amount']= parseFloat(rawAmount_.toFixed(2))
                    price_['base']= parseFloat(rawBase_.toFixed(2))
                    price_['service']= parseFloat(rawService_.toFixed(2))
                    price_['service_flat']= parseFloat(rawService_flat_.toFixed(2))
                    price_['merchant']= parseFloat(rawMerchant_.toFixed(2))
                }
            }
            let newpassengerdict={combination:{indices:indices__,category:"hand_bag",conditions:{passenger_groups:passengergrp_},price:price_}, passengers: [i]};
            for (let j = 0; j < holdBagCount; j++) {
                let holdbagIndexPassenger = localStorage.getItem(`bagHoldIndex${j}_passenger${i}`)
                if (holdbagIndexPassenger !== null) {
                    indices_.push(holdbagIndexPassenger);
                    passengergrp =bookingResponse.baggage.definitions.hold_bag[holdbagIndexPassenger].conditions.passenger_groups
                    let rawAmount = bookingResponse.baggage.definitions.hold_bag[holdbagIndexPassenger].price.amount * localStorage.getItem('rate');
                    let rawBase = bookingResponse.baggage.definitions.hold_bag[holdbagIndexPassenger].price.base * localStorage.getItem('rate');
                    let rawService = bookingResponse.baggage.definitions.hold_bag[holdbagIndexPassenger].price.service * localStorage.getItem('rate');
                    let rawService_flat = bookingResponse.baggage.definitions.hold_bag[holdbagIndexPassenger].price.service_flat * localStorage.getItem('rate');
                    let rawMerchant = bookingResponse.baggage.definitions.hold_bag[holdbagIndexPassenger].price.merchant * localStorage.getItem('rate');
                    price['amount']= parseFloat(rawAmount.toFixed(2))
                    price['base']= parseFloat(rawBase.toFixed(2))
                    price['service']= parseFloat(rawService.toFixed(2))
                    price['service_flat']= parseFloat(rawService_flat.toFixed(2))
                    price['merchant']= parseFloat(rawMerchant.toFixed(2))
                }
            }
            let passengerDict = {combination:{indices:indices_,category:"hold_bag",conditions:{passenger_groups:passengergrp},price:price}, passengers: [i]};
            passengerList.push(passengerDict);
            if(!bookingResponse.baggage.notices.hold_bags_disabled_in_mmb){
                passengerList.push(newpassengerdict);
            }

        }
        const mergedPassengers = passengerList.reduce((merged, item) => {
            const index = item.combination.indices.join('-'); // Get the indices as a string for grouping

            if (!merged[index]) {
                merged[index] = { ...item };
            } else {
                merged[index].passengers = [...new Set([...merged[index].passengers, ...item.passengers])];
            }

            return merged;
        }, {});

        const result = Object.values(mergedPassengers);
        let filteredResult = result.filter(item => item.combination.indices.length > 0);
        if(filteredResult.length === 0){
            const emptyIndices = bookingResponse.baggage.combinations.hold_bag
            const getIndices = emptyIndices.filter(item => item.indices.length === 0);
            filteredResult = [
                {
                    "combination": {
                        "indices": [],
                        "category": "hold_bag",
                        "conditions": {
                            "passenger_groups": emptyIndices[0].conditions.passenger_groups
                        },
                        "price": {
                            "currency": "NGN",
                            "amount": emptyIndices[0].price.amount * localStorage.getItem('rate'),
                            "base": emptyIndices[0].price.base * localStorage.getItem('rate'),
                            "service": emptyIndices[0].price.service * localStorage.getItem('rate'),
                            "service_flat": 0,
                            "merchant": 0
                        }
                    },
                    "passengers": [
                        0
                    ]
                }
            ]
        }
        return filteredResult;
    }
    const token = decrypt(localStorage.getItem('bookingToken'));
    const sessionID = localStorage.getItem('bluk')
    const params = {
        booking_token: token,
        passengers: getPassengers(),
        baggage: getBaggage(),
        session_id: sessionID,
        additional_services:{},
        payment_gateway: "payu"
    }

    const getAPIKEY =()=>{
        const trip = localStorage.getItem('Trip')||'One Way'
        let apikey
        if (trip === 'One Way'){
            apikey = envVariables.APIKEY_RETURN_ONEWAY;
        }
        else{
            apikey = envVariables.APIKEY_MULTICITY;
        }
        return apikey
    }

    const headers = {
        'Content-Type': 'application/json',
        'apikey': getAPIKEY(),
    };
    let save_response = JSON.parse(localStorage.getItem('save_booking_response'))
    if(save_response){
        save_response = save_response.data
    }
    const tokenize_response = JSON.parse(localStorage.getItem('tokenize_response'))||[]
    const tokenizeApi=async ()=>{
        // https://fe.payments-kiwi.com/tokenize/
        //Replace with actual card data
        const tokenize_params={
            card:{
                number:"5555444444444444",//5601070165
                cvv: "123",//765
                expirationMonth: "06",
                expirationYear: "26",
                holder: "TEST APPROVE" //Jarom Travel Agency
            },
            payment: {
                order_id: (save_response.booking_id).toString() ? (save_response.booking_id).toString() : null,
                token: save_response.payu_token,
                gate: "pos",
                email: "test@kiwi.com"
            }

        }
        axios
            .post(`https://fe.payments-kiwi-dev.com/tokenize/`,tokenize_params, {headers})
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    localStorage.setItem('tokenize_response', JSON.stringify(response))
                    zooz_pament()
                }
            })
            .catch((error) => {
                console.log(error,'call customer care')

            });
    };

    const save_booking=()=>{
        axios
            .post(`https://api.tequila.kiwi.com/v2/booking/save_booking`,params, {headers})
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    localStorage.setItem('save_booking_response', JSON.stringify(response))
                }

            })
            .catch((error) => {
                console.log(error,'call customer care')

            });
    };


    function customStringify(obj) {
        const seen = new WeakSet();

        function replacer(key, value) {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return "[Circular Reference]";
                }
                seen.add(value);
            }
            return value;
        }

        return JSON.stringify(obj, replacer);
    }

    const set_to_local = (dataToStore, key_name) => {
        const data_stringify = customStringify(dataToStore);
        localStorage.setItem(key_name, data_stringify);
    }

    const zooz_pament=()=>{

        const paymentParam = {
            payment_details:{
                status: tokenize_response.status,
                token: tokenize_response.token,
                encrypted_cvv: tokenize_response.encrypted_cvv,
                bin_number: tokenize_response.bin_number,
                last_4_digits: tokenize_response.last_4_digits,
                holder_name: tokenize_response.holder_name,
                expiration: tokenize_response.expiration,
                vendor: tokenize_response.vendor,
                issuer: tokenize_response.issuer,
                country_code: tokenize_response.country_code,
                level: tokenize_response.level,
                type: tokenize_response.type,
                pass_luhn_validation: tokenize_response.pass_luhn_validation,
            },
            booking_id:save_response.booking_id||'',
            order_id: save_response.booking_id,
            paymentToken: save_response.payu_token,
            paymentMethodToken: tokenize_response.token,
            sandbox: true,
        };
        axios
            .post(`https://api.tequila.kiwi.com/v2/booking/confirm_payment_zooz`,paymentParam, {headers})
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error,'call customer care')

            });
    }
    const confirmPayment = async () => {
        setPayLoading1(true)
        const code = paymentCode;
        const bid = save_response.booking_id.toString();

            //
        try {
            const requestData = {
                code,
                bid
            };
            const response = await axiosWithAuth(apikey).post('/payment/validate_payment_code/', requestData);
            if (response.status === 200 || response.status === 201) {
                setPayLoading1(false)
                setErrImg('https://res.cloudinary.com/do5wu6ikf/image/upload/v1702572525/starpenzu/Check_g_otgwce.svg');
                setErrmsg('successful')
                tokenizeApi()
            }
        } catch (error) {
            setErrImg('https://res.cloudinary.com/do5wu6ikf/image/upload/v1702572525/starpenzu/Check_r_naosbf.svg')
            setErrmsg('Error')
            setPayLoading1(false)
            console.error('Error fetching data:', error);
        }
    };

    const [selectedOption, setSelectedOption] = useState('bankTrf');


    // Function to handle radio input change
    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const copyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            alert('Text copied to clipboard!');
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
    };

    function nextpay (){
        if(paymentpg < 2){
            setPaymentpg(paymentpg + 1)
        }
    }

    function validate(){
        if(validateFile(file) && file){
            nextpay ()
        }else{
            console.log('not validated')
        }
    }

    function prevpay (){
        if(paymentpg !== 0){
            setPaymentpg(paymentpg - 1)
        }
    }

   const payStyle ={
        background: paymentCode ? '#054DAB' : '#5888C7'
   }

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setImage(URL.createObjectURL(file))
        setFile(file);
        set_to_local(file, 'myRecipt')
        console.log(file, 'file')
    };

    const handleFileeClick = () => {
        // Trigger click on the file input
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const validateFile = (file) => {
        // Reset previous errors
        setError(null);

        // Check if a file is selected
        if (!file) {
            setError('Please select a file.');
            return false;
        }

        // Check file type
        if (!file.type || (!file.type.startsWith('image/') && file.type !== 'application/pdf')) {
            setError('Please upload an image or PDF file.');
            return false;
        }

        const maxSize = 0.5 * 1024 * 1024; // 500kb in bytes
        if (file.size > maxSize) {
            setError('File size exceeds the limit (500kb).');
            return false;
        }

        // If all validations pass, set the selected file
        setFile(file);
        return true;
    };



    return (
        <div className='genpadd'>

            {popup &&
                <div className="popupmain">
                    <div className="popupcont">
                        <div onClick={() => setPopUp(false)}> <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1699982891/starpenzu/iconoir_cancel_dyqpvv.svg" alt=""/></div>
                        <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1702654711/starpenzu/majesticons_mail_cjkwxx.svg" alt="" className="popupimg"/>
                        <div className="headerpop">Email notification</div>
                        <div className="subtextpop">The confrimation code has been sent to your email, kindly check and input it to confirm your payment</div>
                    </div>
                </div>
            }




            <div className="yourTrip" >
                {paymentpg === 2?
                'Confirm payment'
                 :
                'Make Secure Payment'
                }
            </div>
            <br/>

            <div className="paymentRadio">
                <label className='la' htmlFor="cardPayment">
                    <input type="radio" id='cardPayment' onChange={handleRadioChange} value='cardPayment' checked={selectedOption === 'cardPayment'}/>
                    Card Payment
                </label>

                <label className='la' htmlFor="bankTrf">
                    <input type="radio" id='bankTrf' onChange={handleRadioChange} value='bankTrf' checked={selectedOption === 'bankTrf'}/>
                    Bank Transfer
                    {/*<div onClick={() => copyToClipboard('Bank Transfer')}>dodo</div>*/}
                </label>
            </div>
            <div>
                {selectedOption === 'cardPayment' &&
                    <div className="cardPayment">
                        <div className='paymentsubtext'>In Progress....</div>
                    </div>
                }
                {selectedOption === 'bankTrf' &&
                    <div className="bankTrf">
                        {paymentpg === 0 &&
                            <div className="paymentCont">

                                <div className="paymentHeader">
                                    How to pay into our bank account
                                </div>

                                <div className="paymentsubtext">
                                    Visit any bank listed below, and make payment. However, you can make transfer to any of our bank accounts from your mobile banking applications. Once your payment is confirmed, your reservation will be processed.
                                </div>

                                <div className="paymentPricecont">
                                    <div className='BookingAmt'>
                                        Booking Amount
                                    </div>

                                    <div className='Amount'>
                                        {currSign} {stringMainPrice}
                                    </div>
                                </div>

                                <div className="BankCont">
                                    <div className="bnkImg">
                                        <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1702184864/starpenzu/Fidelity-Bank-Old-Logo-Brandessence_1_cr6a3g.svg" alt=""/>
                                        <div className="bnkName">Fidelity Bank</div>
                                    </div>

                                    <div className="accDetails">
                                        <div className="accname">Account Name:</div>
                                        <div className="accDetailsn" onClick={() => copyToClipboard('Jaromtravels Limited') }>Jaromtravels Limited
                                            <span className="copyClip"><img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1702184863/starpenzu/copy_ffkwdq.svg" alt=""/></span>
                                        </div>
                                    </div>

                                    <div className="accDetails">
                                        <div className="accname">Account Number:</div>
                                        <div className="accDetailsn" onClick={() => copyToClipboard('123456478900')}>123456478900
                                            <span className="copyClip"><img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1702184863/starpenzu/copy_ffkwdq.svg" alt=""/></span>
                                        </div>
                                    </div>



                                </div>
                                <div className="myCreateResBtn ECU">
                                                                     {/*<div className="contBtn">*/}
                                        <div className='visa_casing'>
                                            <button className='visa_next' onClick={nextpay} type='submit' disabled>
                                                Create Reservations<span><img
                                                src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                            </button>

                                            <button className='visa_next visaCent' onClick={PrevSeatPage} id='timiButton' >
                                                <img id='visaPrev'
                                                     src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Back</span>
                                            </button>

                                        </div>
                                    {/*</div>*/}
                                </div>
                            </div>
                     }

                        {paymentpg === 1 &&
                            <div>
                                <div className="paymentCont makeInvi">
                                    <div className="paymentHeader">
                                        How to pay into our bank account
                                    </div>

                                    <div className="paymentsubtext">
                                        Visit any bank listed below, and make payment. However, you can make transfer to any of our bank accounts from your mobile banking applications. Once your payment is confirmed, your reservation will be processed.
                                    </div>

                                    <div className="paymentPricecont">
                                        <div className='BookingAmt'>
                                            Booking Amount
                                        </div>

                                        <div className='Amount'>
                                            {currSign} {stringMainPrice}
                                        </div>
                                    </div>
                                </div>
                                <div className='payment_conf'>
                                    <div className="payHeader">
                                        Payment Confirmation
                                    </div>
                                    <div className="paySubText">
                                        For your bookings to be completed, you need to provide an evidence of payment and then you could receive a confirmation code that you have successfully paid into your account from our operators.
                                    </div>

                                    <div className="paymentPricecont">
                                        <div className='BookingAmtconf'>
                                            Booking Amount
                                        </div>

                                        <div className='Amountpayconf'>
                                            {currSign} {stringMainPrice}
                                        </div>
                                    </div>


                                    <div className="filee"

                                    >
                                        <div>

                                            {file &&(
                                                <div>
                                                    {Image &&
                                                        <div>
                                                            <div className='myFileRep'>
                                                                <div className="fileexeIcon">
                                                                    {(file.type || (file.type.startsWith('image/')) )&&
                                                                     <div>
                                                                         <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1702924414/starpenzu/bxs_file-jpg_z12nq3.svg" alt=""/>
                                                                     </div>
                                                                    }
                                                                </div>

                                                                {/*<div className="fileexeIcon">*/}
                                                                {/*    {(file.type ||  (file.type.startsWith('application/pdf'))) &&*/}
                                                                {/*        <div>*/}
                                                                {/*            <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1702924414/starpenzu/Pdf_z00sn6.svg" alt=""/>*/}
                                                                {/*        </div>*/}
                                                                {/*    }*/}
                                                                {/*</div>*/}
                                                                <div className="fileexeName">
                                                                    {file.name}
                                                                </div>
                                                                <div className="closeFile" onClick={() => setFile(null)}>
                                                                    <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1699982891/starpenzu/iconoir_cancel_dyqpvv.svg" alt=""/>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </div>

                                        {!file && !Image ?
                                            ''
                                            :
                                            <input
                                                type="file"
                                                name='reciept Image'
                                                onChange={handleFileChange}
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                            />
                                        }


                                        <div
                                            className='selectfilecont'
                                            onDragOver={(event) => event.preventDefault()}
                                             onDrop={handleDrop}
                                              onClick={handleFileeClick}
                                        >

                                            Select a file <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1702925119/starpenzu/add-circle_ncji60.svg" alt=""/>
                                        </div>
                                        <p className="fileText">Upload your payment receipt in png or jpg format</p>
                                    </div>
                                    <div>
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                    </div>


                                    <div className="myCreateResBtn">
                                        {/*<div className="contBtn">*/}
                                            <div className='visa_casing'>
                                                <button className='visa_next' onClick={validate} type='submit'>
                                                    Confirm Payment<span><img
                                                    src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                                </button>
                                                <button className='visa_next visaCent' onClick={prevpay} id='timiButton' >
                                                    <img id='visaPrev'
                                                         src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Back</span>
                                                </button>
                                            </div>
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        }


                        {paymentpg === 2 &&
                            <div className='payment_conf'>
                                <div className="payHeader">
                                    Payment Confirmation
                                </div>
                                <div className="paySubText">
                                    Input the confirmation code sent to your e-mail to get your receipt and boarding pass.
                                </div>

                                <div className="paymentPricecont">
                                    <div className='BookingAmtconf'>
                                        Booking Amount
                                    </div>

                                    <div className='Amountpayconf'>
                                        {currSign} {stringMainPrice}
                                    </div>
                                </div>


                                <div className='formPay'>
                                    <div className="formpayHeader">
                                        Confirmation Code
                                    </div>
                                    <div className="formpaySubText">
                                        Input code here and wait while your transaction is processed
                                    </div>

                                    {!payLoading ?
                                        <div className="codeReq"  onClick={fetchData}>Request confirmation code</div>
                                    :
                                        <div className="codeReq"  onClick={fetchData}>Please wait..</div>
                                    }
                                    <div className="myIn">
                                        <div className="inpcomp">
                                            <input
                                                className={errmsg === 'successful' ? 'confSuc' : (errmsg === 'Error' ? 'confErr' : 'confForm')}

                                                type="text"
                                                value={paymentCode}
                                                onChange={(e) => setPaymentcode(e.target.value)}
                                            />
                                            <div className='errc'>{errmsg}</div>
                                        </div>

                                        <div>
                                            <img src={errImg} alt=""/>
                                        </div>
                                    </div>

                                    {/*<div>*/}
                                    {/*    {payLoading && <div className="codeReq" >Loading..</div>}*/}
                                    {/*</div>*/}

                                </div>



                                <div className="myCreateResBtn">
                                    {/*<div className="contBtn">*/}
                                        <div className='visa_casing'>
                                            <button className='visa_next'  style={payStyle}  disabled={!paymentCode}  onClick={confirmPayment} type='submit'>
                                                {payLoading1 ? 'Loading....' : ' Confirm Payment'}<span><img
                                                src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                            </button>

                                            <button className='visa_next visaCent' onClick={prevpay} id='timiButton' >
                                                <img id='visaPrev'
                                                     src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Back</span>
                                            </button>

                                        </div>
                                    {/*</div>*/}
                                </div>
                            </div>

                        }



                        {/*hover on customer care should give numbers to call and emails to reach out to*/}

                    </div>
                }
            </div>
        </div>
    );
};

export default PaystackComponent;
