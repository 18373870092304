import React from 'react';
import TitleDropdown from "./titleDropdown";
import {useEffect, useState} from "react";

function VisaDoubleDropdownInputField({labelName, star, localStorageName, localStorageNameInput, name, type, placeholder, required}) {
    const options1 = ['Mr', 'Ms'];
    const [selectedValue, setSelectedValue] = useState('');
    useEffect(() => {
        const storedValue = localStorage.getItem(localStorageNameInput);
        // console.log('newValue',storedValue)
        if (storedValue) {
            setSelectedValue(storedValue);
        }
    }, [localStorageNameInput]);
    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        localStorage.setItem(localStorageNameInput, newValue);
    };
    return (
        <div className='visa_inputField '>
            <label>
                {labelName}<span>{star}</span>
            </label><br/>
            <div className='visa_Double'>
                <TitleDropdown localStorageName={localStorageName} mfont='10px' mxfont='14px' mxwidth='88px' width='78px' borderL='0px' height='41px' borderR='0px' options={options1} title="Title" className='visa_input' id='firstInput'/>
                <input className='visa_input myinput' id='secondInput' name={name} type={type}
                       placeholder={placeholder} value={selectedValue} onChange={handleSelectChange}
                       // required={required}
                />
            </div>
        </div>
    );
}

export default VisaDoubleDropdownInputField;
