import React from "react";
import ContactUsButton from "./ContactUs_button";

export default function AboutMain (){
    return(

        <>


            <div className="aboutMain">
                <p>
                    Jarom Travels Agency Limited is wholly a Nigerian company incorporated in Nigeria, as a limited liability company, under the COMPANY ALLIED AND MATTERS ACT (CAMA) of 1990. <br/>
                    We have grown into a full-fledged travel company providing top-notch services such as Corporate, Business, & Group Travel, Flight Ticket, Cruises, Visa applications and Processes, Studies Abroad, Airport Pickups, Hotel reservations, Special Event Planning, All-Inclusive Resort Stays, Destination Weddings & Honeymoons, Vacations, and Tours etcetera
                </p>

                <h1>Mission</h1>
                <p>
                    Our mission is to become the first-choice travel Agency for institutions and clients around the world, adding more valuable
                    services to customers in the Travel industry
                </p>


                <h1>Vision</h1>
                <p>
                    Integrating travel agencies to NEXT LEVEL to achieve maximum growth and satisfy our
                </p>
            </div>

            <div className="contactUs">
                <h1>Your dream of traveling or schooling abroad
                    is very possible with Jarom Travels
                </h1>

                <p>
                    Our commitment to professional excellence ensures that our clients receive the
                    highest quality service. We aspire to provide flawless execution and delivery of our products and services to make your dream a reality..
                </p>
                <ContactUsButton
                    className='AboutUsButton'
                    btn_Name="Contact Us"
                />
            </div>
        </>

    )
}