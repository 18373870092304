import axios from "axios";
import {useState, useEffect, useContext} from "react";
import arrow from "../../images/arrow-down.png";
import NumberCounter from "./NumberCounter";
import CountrySelector from "./CountryButtons";
import CalendarButton from "./DateButtons";
import {Link} from "react-router-dom";
import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Calender from "./Calender";
import ReloadContext from "../Context/Reload";
import {encrypt} from "../Security";
import SubstituteSkeleton from "../SkeletonLoadingComponent/HomeSkeleton/SubstituteSkeleton";


function Substitute({isFlight, isVisa, className, handleSubmit, loading}) {
    window.onload = function handleSubmit () {
        const firstButton = document.getElementById('firstButton');
        const secButton = document.getElementById('secButton');

        firstButton.addEventListener('click', () => {
            firstButton.classList.add('clicked');
            secButton.classList.remove('clicked');
        });

        secButton.addEventListener('click', () => {
            secButton.classList.add('clicked');
            firstButton.classList.remove('clicked');
        });
    }



    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(() => localStorage.getItem('Trip') || 'One Way');
    const [isDropdownOpen__, setIsDropdownOpen__] = useState(false);
    const [isDropdownOpen___, setIsDropdownOpen___] = useState(false);
    const [selectedOption__, setSelectedOption__] = useState(localStorage.getItem('Ticket') ||'Economy');
    const [selectedOption___, setSelectedOption___] = useState(localStorage.getItem('Currency') ||'NGN');
    const [isDropdownOpen_, setIsDropdownOpen_] = useState(false);
    const [selectedOption_, setSelectedOption_] = useState(' Passenger');
    const [adult, setAdult] = useState(parseInt(localStorage.getItem('adults'), 10) || 1);
    const [children, setChildren] = useState(parseInt(localStorage.getItem('children'), 10) || 0);
    const [infant, setInfant] = useState(parseInt(localStorage.getItem('infant'), 10) || 0);
    const [total, setTotal] = useState(parseInt(localStorage.getItem('totalPassengers'), 10) || 0);
    const [hasClearedLocalStorage, setHasClearedLocalStorage] = useState(false);

    // const [isFlight, setIsFlight] = useState(true);
    // const [isVisa, setIsVisa] = useState(false);
    const [multiCity, setMultiCity] = useState([{ id: 1, value: null }]);

    //const [isRoundTrip, setIsRoundTrip] = useState('');

    const handleAddMultipleInput = () => {
        const newId = multiCity.length + 1;
        localStorage.setItem('Multi-City', newId);
        setMultiCity([...multiCity, { id: newId, value: null }]);
        setShowCalenders((prevCalenders) => [
            ...prevCalenders,
            { showCalender: false, showCalender2: false },
        ]);
        setShowCountries((prevCountries) => [
            ...prevCountries,
            {showCountrySelector1: false, showCountrySelector2: false, showCountrySelector3: false,
                showCountrySelector4: false },
        ]);
    };
    const handleSubtractMultipleInput = () => {
        if (multiCity.length > 1) {
            const count = multiCity.length - 1
            const newMultiCity = multiCity.slice(0, count);
            setMultiCity(newMultiCity);
            localStorage.setItem('Multi-City', count);
            const newShowCalenders = showCalenders.slice(0, showCalenders.length - 1);
            setShowCalenders(newShowCalenders);
            localStorage.removeItem(`leavingDate${multiCity.length}`);
            localStorage.removeItem(`returningDate${multiCity.length}`);
            localStorage.removeItem(`retFrom${multiCity.length}`);
            localStorage.removeItem(`retTo${multiCity.length}`);
            localStorage.removeItem(`toCountry${multiCity.length}`);
            localStorage.removeItem(`fromCountry${multiCity.length}`);
        }

    };
    const [showCalenders, setShowCalenders] = useState(
        multiCity.map(() => ({ showCalender: false, showCalender2: false }))
    );
    const [showCountries, setShowCountries] = useState(
        multiCity.map(() => ({ showCountrySelector1: false,
            showCountrySelector2: false, showCountrySelector3: false,
            showCountrySelector4: false }))
    );


    const handleButtonClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsDropdownOpen_(false);
        setIsDropdownOpen__(false);
        setIsDropdownOpen___(false);
    };
    const handleThirdButtonClick = () => {
        setIsDropdownOpen__(!isDropdownOpen__);
        setIsDropdownOpen___(false);
        setIsDropdownOpen_(false);
        setIsDropdownOpen(false);
    };
    const handleSecButtonClick = () => {
        setIsDropdownOpen_(!isDropdownOpen_);
        setIsDropdownOpen(false);
        setIsDropdownOpen__(false);
        setIsDropdownOpen___(false);
    }
    const handlefortButtonClick = () => {
        setIsDropdownOpen_(false);
        setIsDropdownOpen(false);
        setIsDropdownOpen__(false);
        setIsDropdownOpen___(!isDropdownOpen___);
    }
    const handlefortOptionClick = (option) => {
        setSelectedOption___(option);
        setIsDropdownOpen___(false);
        localStorage.setItem("Currency", option);
    };

    const handleThirdOptionClick = (option) => {
        setSelectedOption__(option);
        setIsDropdownOpen__(false);
        localStorage.setItem("Ticket", option);
    };
    // let tripLocal2 = selectedOption;
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsDropdownOpen(false);
        console.log("Option", option)
        localStorage.setItem("Trip", option);
        // makeReload();
    };
    // useEffect(() => {
    //     if (!hasClearedLocalStorage) {
    //         localStorage.removeItem('Trip');
    //         setHasClearedLocalStorage(true);
    //     }
    // }, [hasClearedLocalStorage]);
    // useEffect(() => {
    //     localStorage.setItem('Trip', selectedOption);
    //     console.log(selectedOption, 'pheew')
    // }, [selectedOption]);
    const updateValue = (name, value) => {
        switch (name) {
            case "adult":
                setAdult(value);
                break;
            case "children":
                setChildren(value);
                break;
            case "infant":
                setInfant(value);
                break;
            default:
                break;
        }
    };
    const handleValueUpdate = (name, value) => {
        updateValue(name, value);
    };
    useEffect(() => {
        const sum = adult + children + infant;
        setTotal(sum);
        localStorage.setItem('totalPassengers', sum);
        handleReload();
    }, [adult, children, infant]);
    useEffect(() => {
        localStorage.setItem('adults', adult);
        localStorage.setItem('children', children);
        localStorage.setItem('infant', infant);
    }, [adult, children, infant]);

    const handleCountrySelectorVisibility = (index, selector, value) => {
        setShowCountries((prevShowCountries) => {
            const updatedShowCountries = [...prevShowCountries];
            updatedShowCountries[index][selector] = value;
            return updatedShowCountries;
        });
    };
    const handleCountrySelectorClick1 = (index) => {
        setShowCountries((prevShowCountries) => {
            const updatedShowCountries = [...prevShowCountries];

            // Close all other country selectors except the clicked one
            for (let i = 0; i < updatedShowCountries.length; i++) {
                if (i === index) {
                    updatedShowCountries[index] = {
                        showCountrySelector4: !prevShowCountries[index].showCountrySelector4,
                        showCountrySelector1: false
                    };
                }
                else if (i !== index) {
                    updatedShowCountries[i] = {
                        showCountrySelector4: false,
                        showCountrySelector1: false
                    };
                }
            }
            return updatedShowCountries;
        });
    };
    const handleCountrySelectorClick2 = (index) => {
        setShowCountries((prevShowCountries) => {
            const updatedShowCountries = [...prevShowCountries];

            // Close all other country selectors except the clicked one
            for (let i = 0; i < updatedShowCountries.length; i++) {
                if (i === index) {
                    updatedShowCountries[index] = {
                        showCountrySelector1: !prevShowCountries[index].showCountrySelector1,
                        showCountrySelector4: false
                    };
                }
                else if (i !== index) {
                    updatedShowCountries[i] = {
                        showCountrySelector4: false,
                        showCountrySelector1: false
                    };
                }
            }
            return updatedShowCountries;
        });
    };


    const Search = () => {
        if (window.location.href === 'https://jaromtravels.com/#/SearchResult') {
            handleSubmit();
        }
        else{
            window.location.href = '#/SearchResult';
        }
    };
    const [isRoundTrip, setIsRoundTrip] = useState('');

    useEffect(() => {
        const tripClick = () => {
            const storedValue = localStorage.getItem('Trip');
            if (storedValue) {
                setIsRoundTrip(storedValue);
            }
        };

        tripClick();
    }, [selectedOption]);
    const handleCalenderClick = (index) => {
        setShowCalenders((prevCalenders) => {
            const updatedCalenders = [...prevCalenders];
            for (let i = 0; i < updatedCalenders.length; i++) {
                if (i === index) {
                    updatedCalenders[index] = {
                        showCalender: !prevCalenders[index].showCalender,
                        showCalender2: false,
                    };
                }
                else if (i !== index) {
                    updatedCalenders[i] = {
                        showCalender2: false,
                        showCalender: false
                    };
                }

            }
            return updatedCalenders;
        });
    };

    const handleCalender2Click = (index) => {
        setShowCalenders((prevCalenders) => {
            const updatedCalenders = [...prevCalenders];
            for (let i = 0; i < updatedCalenders.length; i++) {
                if (i === index) {
                    updatedCalenders[index] = {
                        showCalender: false, // Close other calendar when this is opened
                        showCalender2: !prevCalenders[index].showCalender2,
                    };
                }
                else if (i !== index) {
                    updatedCalenders[i] = {
                        showCalender2: false,
                        showCalender: false
                    };
                }
            }
            return updatedCalenders;
        });
    };

    const { setReloadFlag } = useContext(ReloadContext);

    const handleReload = () => {
        setReloadFlag(true);
    };
    if(!localStorage.getItem('CabinBaggage')  && !localStorage.getItem('CheckedBaggage') && !localStorage.getItem('Currency')){
        localStorage.setItem('CabinBaggage', encrypt(String('0')));
        localStorage.setItem('CheckedBaggage', encrypt(String('0')));
        localStorage.setItem('Currency', 'NGN');
    }


    const multicitystyle ={
        flexDirection: (isRoundTrip === 'Multi-City' || isRoundTrip ===  'Multi-City (Cheapest)') ? 'column' : ''
    }

    const makeReload = () =>{
        // if((isRoundTrip === 'One Way' || isRoundTrip === 'Round Trip')){
            window.location.reload();
        // }else {
        //     return 0
        // }
    }




    return (
        <div>
                <div className={`substitute ${className}`}>
                    {isFlight && (
                        <div>
                            <div className='flightOptions'>
                                <div className="dropdown-container">
                                    <button className="dropdown-button" onClick={handleButtonClick}>
                                        {selectedOption} <span><img id='flightOptions-button-span' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687751320/arrow-down_s79bkv.svg'/></span>
                                    </button>
                                    <div className='dropdowns'>
                                        {isDropdownOpen && (
                                            <div className="dropdown-menu" id='dropdown-menu-fir'>
                                                <div className="dropdown-option"
                                                     onClick={() => handleOptionClick('Round Trip')}>
                                                    Round Trip
                                                </div>
                                                <div className="dropdown-option"
                                                     onClick={() => handleOptionClick('One Way')}>
                                                    One Way
                                                </div>
                                                <div className="dropdown-option"
                                                     onClick={() => handleOptionClick('Multi-City')}>
                                                    Multi-City
                                                </div>
                                                <div className="dropdown-option"
                                                     onClick={() => handleOptionClick('Multi-City (Cheapest)')}>
                                                    Multi-City (Cheapest)
                                                </div>
                                            </div>
                                        )}
                                        {isDropdownOpen_ && (
                                            <div className="dropdown-menu" id='dropdown-menu-sec' >
                                                <div className="dropdown-option">
                                                    <div className='inline' id='float'>
                                                        <div className='textLeft'>Adults <br/> <span>>12years </span></div>
                                                        <div className='ageSubtract'>
                                                            <NumberCounter
                                                                name="adult"
                                                                valueStopper={total}
                                                                defaultValue={adult}
                                                                step={1}
                                                                adult={1}
                                                                infant={0}
                                                                updateValue={handleValueUpdate}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="dropdown-option">
                                                    <div className='inline'>
                                                        <div className='textLeft'>Children<br/> <span>2-12 years</span>
                                                        </div>
                                                        <div className='ageSubtract'>
                                                            <NumberCounter
                                                                name="children"
                                                                defaultValue={children}
                                                                valueStopper={total}
                                                                step={1}
                                                                adult={1}
                                                                infant={0}
                                                                updateValue={handleValueUpdate}/>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="dropdown-option">
                                                    <div className='inline' id='float'>
                                                        <div className='textLeft'>Infants<br/> <span>&lt;2 years</span>
                                                        </div>
                                                        <div className='ageSubtract'>
                                                            <NumberCounter
                                                                name="infant"
                                                                valueStopper={total}
                                                                defaultValue={infant}
                                                                step={1}
                                                                adult={adult}
                                                                infant={infant}
                                                                updateValue={handleValueUpdate}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                        {isDropdownOpen__ && (
                                            <div className="dropdown-menu" id='dropdown-menu-thi'>
                                                <div className="dropdown-option"
                                                     onClick={() => handleThirdOptionClick('Economy')}>
                                                    Economy
                                                </div>
                                                <div className="dropdown-option"
                                                     onClick={() => handleThirdOptionClick('Premium Economy')}>
                                                    Premium Economy
                                                </div>
                                                <div className="dropdown-option"
                                                     onClick={() => handleThirdOptionClick('Business')}>
                                                    Business
                                                </div>
                                                <div className="dropdown-option"
                                                     onClick={() => handleThirdOptionClick('First Class')}>
                                                    First Class
                                                </div>
                                            </div>
                                        )}
                                        {isDropdownOpen___ && (
                                            <div className="dropdown-menu" id='dropdown-menu-thi'>
                                                <div className="dropdown-option"
                                                     onClick={() => handlefortOptionClick('NGN')}>
                                                    NGN
                                                </div>
                                                <div className="dropdown-option"
                                                     onClick={() => handlefortOptionClick('USD')}>
                                                    USD
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>

                                <button className='dropdown-button' onClick={handleSecButtonClick}>
                                    {total}{selectedOption_} <span><img id='flightOptions-button-span' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687751320/arrow-down_s79bkv.svg'/></span>
                                </button>

                                <button className='dropdown-button' onClick={handleThirdButtonClick}>
                                    {selectedOption__} <span><img id='flightOptions-button-span' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687751320/arrow-down_s79bkv.svg' /></span>
                                </button>
                                <button className='dropdown-button' id='currencyButton' onClick={handlefortButtonClick}>
                                    {selectedOption___} <span><img id='flightOptions-button-span' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687751320/arrow-down_s79bkv.svg'/></span>
                                </button>
                            </div><br/>
                            <div className='countryKitchen' style={multicitystyle}>
                                {multiCity.map((input, index) => (
                                    <div className='countryKitchen' key={input.id} data-id={input.id}>

                                        <CountrySelector
                                            key={input.id} data-id={input.id}
                                            countrySelect={`fromCountry${index}`}
                                            imageSrc="https://res.cloudinary.com/dk80ynx1w/image/upload/v1679668431/airplane_mtbgrr.svg"
                                            firstText="From" onclick={() => handleCountrySelectorClick1(index)} choice={showCountries[index].showCountrySelector4}
                                            setShowList={(value) => handleCountrySelectorVisibility(index, 'showCountrySelector1', value)}>
                                        </CountrySelector>
                                        <CountrySelector
                                            countrySelect={`toCountry${index}`}
                                            imageSrc="https://res.cloudinary.com/dk80ynx1w/image/upload/v1680645358/location-add_jkmt8c.svg"
                                            firstText="To" onclick={() => handleCountrySelectorClick2(index)}
                                            choice={showCountries[index].showCountrySelector1}
                                            setShowList={(value) => handleCountrySelectorVisibility(index, 'showCountrySelector4', value)}>
                                        </CountrySelector>

                                        <div className='time'>
                                            <CalendarButton text="Leaving on"
                                                            idBorder={isRoundTrip === 'Round Trip' ? 'correctBorderI' : 'notRoundTrip'}
                                                            onClick={() => handleCalender2Click(index)}
                                                            storageKey={`leavingDate${index}`} storageKey2={`returningDate${index}`}
                                                            image="https://res.cloudinary.com/dk80ynx1w/image/upload/v1680787591/calendar-2_x4dm9e.svg"
                                            >
                                            </CalendarButton>
                                            {showCalenders[index].showCalender2 && (
                                                <Calender storageKey={`leavingDate${index}`} storageKey2={`returningDate${index}`}/>
                                            )}

                                            {isRoundTrip === 'Round Trip' && (
                                                <CalendarButton text="Returning on"
                                                                idBorder='correctBorderII'
                                                                storageKey={`retFrom${index}`} storageKey2={`retTo${index}`}
                                                                onClick={() => handleCalenderClick(index)}
                                                                image="https://res.cloudinary.com/dk80ynx1w/image/upload/v1680787591/calendar-2_x4dm9e.svg"
                                                >
                                                </CalendarButton>
                                            )}
                                            {showCalenders[index].showCalender && (
                                                <Calender storageKey={`retFrom${index}`} storageKey2={`retTo${index}`}/>
                                            )}
                                        </div>

                                        {
                                            index === 0 ?
                                                ((isRoundTrip === 'Multi-City' || isRoundTrip ===  'Multi-City (Cheapest)') &&
                                                    <div className="makeinvi">
                                                        <div  onClick={handleSubtractMultipleInput}>
                                                            <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1692799467/starpenzu/Group_6846_qn2iat.svg" alt=""/>
                                                        </div>
                                                    </div>
                                                )
                                                :

                                                ( (isRoundTrip === 'Multi-City' || isRoundTrip ===  'Multi-City (Cheapest)') &&
                                                    (
                                                        <div className='delbtn'>
                                                            <div  onClick={handleSubtractMultipleInput}>
                                                                <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1692799467/starpenzu/Group_6846_qn2iat.svg" alt=""/>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                        }

                                    </div>
                                ))}
                                <div className='searchButtonCase'>
                                    {(isRoundTrip === 'Multi-City' || isRoundTrip ===  'Multi-City (Cheapest)') &&
                                        (
                                            <div className='addbtncont'>
                                                <div className='addbtn' onClick={handleAddMultipleInput}>
                                                    <span className='addimg'><img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1692799467/starpenzu/add_u8rhpc.svg" alt=""/></span>
                                                    Add another flight
                                                </div>
                                            </div>
                                        )}


                                    <button id='time-button' onClick={Search}>Search Flight</button>
                                    {/*Ignore*/}
                                    <div className="makeinvi">
                                        <div>
                                            ju
                                        </div>
                                    </div>

                                </div>



                            </div>

                        </div>

                    )}
                    {isVisa && (
                        <div className='solve' >
                            <div className="giving">
                                <Link to='/visa'>
                                    <button id='visa-button'>VISA</button>
                                </Link>
                                <button id='visa-button2'>IELTS/TOEFL/SAT</button>
                            </div>
                        </div>

                    )}
                </div>
        </div>
    );
}

export default Substitute;
