import React from 'react';
import BoxTabSkeleton from "../SkeletonLoadingComponent/HomeSkeleton/BoxTabSkeleton";

const BoxTab = ({ image, text, subText, loading }) => {
    return (
        <div>

                <div className="individual">
                    <div className="small">
                        <img src={image} alt="image" />
                    </div>
                    <div className="text">
                        {text}<br/><span>{subText}</span>
                    </div>
                </div>


        </div>
    );
};

export default BoxTab;
