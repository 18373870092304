import React, { useState, useEffect } from 'react';

const RelationshipRadioInput = ({ text, localStorageName, onClick }) => {
    const [color, setColor] = useState('');

    useEffect(() => {
        const storedColor = localStorage.getItem(localStorageName);
        if (storedColor==='Spouse') {
            setColor('blue');
        }
        else if (storedColor==='Domestic Staff') {
            setColor('red');
        }
        else if (storedColor==='Group') {
            setColor('pink');
        }
        else if (storedColor==='Others') {
            setColor('black');
        }
        else if (storedColor==='Child') {
            setColor('white');
        }

    }, [localStorageName]);

    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setColor(newColor);
        if (newColor==='blue') {
            localStorage.setItem(localStorageName, 'Spouse');
        }
        else if (newColor==='red') {
            localStorage.setItem(localStorageName, 'Domestic Staff');
        }
        else if (newColor==='pink') {
            localStorage.setItem(localStorageName, 'Group');
        }
        else if (newColor==='black') {
            localStorage.setItem(localStorageName, 'Others');
        }
        else if (newColor==='white') {
            localStorage.setItem(localStorageName, 'Child');
        }


    };

    return (
        <>
            <div>
                <div className='visa_applicant visa_dull' id='visa_topper'>Relationship to
                    you?<span>*</span></div>
                <div className='visa_choice visa_dull'>
                    <input
                        type="radio"
                        name={localStorageName}
                        value="blue"
                        checked={color === "blue"}
                        onChange={handleColorChange}
                    />
                    <label htmlFor="blue">Spouse</label>

                    <input
                        type="radio"
                        name={localStorageName}
                        value="red"
                        checked={color === "red"}
                        id='visa_rad'
                        onChange={handleColorChange}
                    />
                    <label htmlFor="red">Domestic Staff</label><br/>
                    <input
                        type="radio"
                        name={localStorageName}
                        value="pink"
                        checked={color === "pink"}
                        onChange={handleColorChange}
                    />
                    <label htmlFor="pink">Group&nbsp;&nbsp;</label>

                    <input
                        type="radio"
                        name={localStorageName}
                        value="black"
                        checked={color === "black"}
                        id='visa_rad'
                        onChange={handleColorChange}
                    />
                    <label htmlFor="black">Others</label><br/>
                    <input
                        type="radio"
                        name={localStorageName}
                        value="white"
                        checked={color === "white"}

                        onChange={handleColorChange}
                    />
                    <label htmlFor="white">Child/Children</label>

                </div>
            </div>
        </>
    );
};

export default RelationshipRadioInput;
