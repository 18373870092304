import React, {useState} from 'react'
import VisaDoubleDropdownInputField from "../Visa/visaDoubleDropDown";
import VisaInputField from "../Visa/visaInput";
import TitleDropdown from "../Visa/titleDropdown";
import VisaDoubleInputField from "../Visa/visaDoubleInput";
import Baggage from "./Baggage";
import InsuranceRadio from "./InsuranceRadio";


export default function DuplicatePassengers({insurance,loading ,isSelectedFirst,onDeselectFirst ,onSelectFirst, countries, gender, index ,age, status, cabinBaggagePrice,cabinHandBaggagePrice,cabinHandBaggagePriceDetails, cabinBaggagePriceDetails, passangerIndex, setCurrrConv, accumulateDatafrominsure}){
    const[fastCheckOut, setFastCheckOut] = useState(false)
    const[noCheckedBaggage, setNoCheckedBaggage] = useState(false)
    const currency = localStorage.getItem('Currency');
    let price_ = '';
    if (currency === 'NGN') {
        price_ = '₦';
    } else if (currency === 'USD') {
        price_ = '$';
    } else if (currency === 'EUR') {
        price_ = '€';
    }




    const handleCheckBoxesChange = (event) =>{
        setFastCheckOut(event.target.checked);
    }

    const handleCheckBoxesChange2 = (event) =>{
        setNoCheckedBaggage(event.target.checked);
    }

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    localStorage.setItem(`fastCheckOut_${passangerIndex}`, fastCheckOut);
    localStorage.setItem(`noCheckedBaggage_${passangerIndex}`, noCheckedBaggage);
    localStorage.setItem('passengerIndex', passangerIndex+1);
    localStorage.setItem('dateOOfBirthBookingPage0', 2003-12-22);

    return(
        <div >
            <div className="priCont">
                <div className="priContText">
                    Passenger {index+1} ({age} - {status} Contact)
                </div>

                <div className="priContBtn">
                    <button className='mainBtn'>Use Profile Information</button>
                </div>

            </div>
            <div  className="mainFormCont">
                <div className='mainFormCol1'>
                    <VisaDoubleDropdownInputField localStorageName={`FirstNameTitleBookingPage${index}`}
                                                  localStorageNameInput={`SurnameBookingPage${index}`}
                                                  labelName='Surname'
                                                  star='*'
                                                  firstPlaceHolder='Title'
                                                  type='text'
                                                  // required={false}
                                                  name='Surname'
                                                  placeholder='Last Name as on intl. passport'>

                    </VisaDoubleDropdownInputField>

                    <VisaInputField labelName='First name' star='*' type='text'
                                    localStorageName={`FirstNamebookingPage${index}`}
                                    placeholder='First Name as on intl. passport' required={false}>

                    </VisaInputField>
                </div>

                <div className='mainFormCol2'>
                    <div className="firstCol">
                        <VisaInputField labelName='Middle name' star='*' type='text'
                                        localStorageName={`MiddleNamebookingPage${index}`}
                                        placeholder='Middle Name as on intl. passport' required={false}>

                        </VisaInputField>
                    </div>

                    <div className="SecCol">
                        <div className='visa_inputField ' id='inputTop'>
                            <label>
                                Nationality<span>*</span>
                            </label>
                            <TitleDropdown required={false} name='Nationality' mfont='10px' mxfont='14px'
                                           mwidth='113px' mxwidth='158px'
                                           localStorageName={`NationalityBookingPage${index}`} options={countries}
                                           title="Country"
                                           className='visa_input'/>
                        </div>
                        <div className='visa_inputField ' id='inputTop'>
                            <label>
                                Gender<span>*</span>
                            </label>
                            <TitleDropdown required={false} name='Gender' localStorageName={`GenderBookingPage${index}`} mwidth='113px'
                                           mxfont='14px' mxwidth='158px' mfont='10px'
                                           options={gender} title="Select"
                                           className='visa_input'/>
                        </div>
                    </div>
                </div>

                <div className='mainFormCol3'>
                    <VisaInputField localStorageName={`dateOOfBirthBookingPage${index}`} labelName='Date of Birth'
                                    star='*'
                                    type='date'
                                    placeholder=''
                                    required={false}>

                    </VisaInputField>
                </div>


            </div>

            <div className="passDetails">
                <div className="passDetailsText">Passport Details</div>
                <div className="redNotice2">
                    <div className="redNotice2Text">All details provided by you must be accurate, true and
                        non-misleading. In the event you provide the wrong information you shall be held liable
                        for such misconduct.
                    </div>
                </div>
            </div>

            <div className="mainForm2">
                <div className="colAll">
                    <VisaInputField labelName='Passport Number' star='*' type='text'
                                    localStorageName={`PassportNumberBookingPage${index}`}
                                    placeholder='Passport Number' required={true}>

                    </VisaInputField>

                    <VisaInputField localStorageName={`PassportExpiryDateBookingPage${index}`} labelName='Passport Expiry Date'
                                    star='*'
                                    type='date'
                                    placeholder=''
                                    required={true}>

                    </VisaInputField>

                </div>
            </div>

            <div className="checkboxw">
                <input id='checkboxw' type="checkbox" checked={fastCheckOut} onChange={handleCheckBoxesChange}/>
                <label htmlFor="checkboxw">
                    Save the traveller info and contact detail for a <span className="checkboxwSpan">fast checkout.</span>
                </label>
            </div>

            <div className="confirm">
                <div className="where">
                    <div className="whereText">Where should we send your confirmation?</div>

                    <div className="whereCont">
                        <div className="whereContText">
                            Your personal data is protected.
                        </div>

                        <div className="whereContTextImg">
                            <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1690182244/starpenzu/ssl-security-lock.png_fl3fgs.png" alt=""/>
                        </div>

                    </div>
                </div>

                <div className="mainForm3">

                    <VisaInputField labelName='Email' star='*' type='email'
                                    localStorageName={`EmailBookingPage${index}`}
                                    placeholder='example@gmail.com'
                                    // required={true}
                    >

                    </VisaInputField>

                    <VisaDoubleInputField localStorageName={`homePhoneNumberBookingPage${index}`}
                                          localTitleStorage={`homePhoneNumberCodeBookingPage${index}`}
                                          labelName='Phone Number' star='*'
                                          firstPlaceHolder='+234' type='tel'
                                          placeholder='Phone Number'
                                          // required={true}
                                          value_i='+234'>
                    </VisaDoubleInputField>

                </div>



            </div>
            { age !== 'Infant' && (
                <div className="baggage">

                    <div className="CarryBaggage">
                        <div className="CarryBaggageText">Cabin or Carry on Baggage</div>
                        <div className="CarryBaggageSubText">Select one option. Different airlines have
                            different limits on baggage size, so we’re showing you the maximum allowed size for your itinerary.
                        </div>

                        <div>
                        { cabinHandBaggagePrice.length > 0 && loading !== undefined &&(


                                    <Baggage
                                        name={'Baggagecheck'}
                                        // localStorageName={`bagHandIndex0_passenger${passangerIndex}`}
                                        // index='0'
                                        loading={loading}
                                        popular={false}
                                        type='top'
                                        isSelectedSec={isSelectedFirst('1x Personal Item')}
                                        onSelectSec={onSelectFirst}
                                        onDeselectSec={onDeselectFirst}
                                        checkid={`iffor_${passangerIndex}`}
                                        item='1x Personal Item'
                                        description={`${cabinHandBaggagePriceDetails[0].weight} kg`}
                                        dimen={`${cabinHandBaggagePriceDetails[0].length} × ${cabinHandBaggagePriceDetails[0].width} × ${cabinHandBaggagePriceDetails[0].height} cm`}
                                        priceBag={`${price_}${cabinHandBaggagePrice[0]}`}
                                        price ={cabinHandBaggagePrice[0]}
                                        bagImg="https://res.cloudinary.com/do5wu6ikf/image/upload/v1690259665/starpenzu/fastening_travel_backpack_bjrjra.svg"
                                    />

                        )}
                        </div>

                        {cabinHandBaggagePriceDetails.length !== 0 && (
                            <div>
                                    <>
                                            <>
                                                {cabinHandBaggagePriceDetails.slice(1).map((cabinDetails, cabinDetailsIndex) =>(
                                                    <div key={cabinDetailsIndex}>
                                                        <Baggage
                                                            name={'Baggagecheck'}
                                                            localStorageName={`bagHandIndex${cabinDetailsIndex + 1}_passenger${passangerIndex}`}
                                                            index={cabinDetailsIndex+1}
                                                            type='top'
                                                            popular={true}
                                                            isSelectedSec={isSelectedFirst('Carry-on bundle')}
                                                            onDeselectSec={onDeselectFirst}
                                                            onSelectSec={onSelectFirst}
                                                            checkid={`iffor2_${passangerIndex}`}
                                                            item='Carry-on bundle'
                                                            description={`1× personal item (${cabinDetails[0].weight} kg) + Priority boarding (easyJet)`}
                                                            dimen={`${cabinDetails[0].length} × ${cabinDetails[0].width} × ${cabinDetails[0].height} cm `}
                                                            priceBag={`${price_}${cabinHandBaggagePrice[1][cabinDetailsIndex]}`}
                                                            price={cabinHandBaggagePrice[1][cabinDetailsIndex]}
                                                            bagImg="https://res.cloudinary.com/do5wu6ikf/image/upload/v1690259666/starpenzu/Group_133546_i5ukwx.svg"
                                                        />
                                                    </div>
                                                ))}
                                            </>
                                    </>
                            </div>
                        )}




                    </div>

                    <div className="CarryBaggage">
                        <div className="CarryBaggageText">Checked baggage</div>
                        <div className="CarryBaggageSubText">Different airlines have different limits on baggage size, so we’re showing you the maximum allowed size for
                            your itinerary.
                        </div>


                        {loading !== undefined && cabinBaggagePrice.map((passenger,index) =>(
                            <div key={index}>
                                    <Baggage
                                        name={'Baggagecheck'}
                                        loading={loading}
                                        localStorageName={`bagHoldIndex${index}_passenger${passangerIndex}`}
                                        index={index}
                                        popular={false}
                                        onSelectSec={onSelectFirst}
                                        onDeselectSec={onDeselectFirst}
                                        isSelectedSec={isSelectedFirst(`${index+1} × checked bag`)}
                                        checkid={`iffor ${index}${index}`}
                                        item={`${index+1} × checked bag`}
                                        description={`${cabinBaggagePriceDetails[index].weight}kg`}
                                        dimen={`${cabinBaggagePriceDetails[index].height} × ${cabinBaggagePriceDetails[index].length} × ${cabinBaggagePriceDetails[index].width} cm `}
                                        priceBag={`${price_}${passenger}`}
                                        price={passenger}
                                        bagImg="https://res.cloudinary.com/do5wu6ikf/image/upload/v1690259666/starpenzu/suitcase_blue_lfyjqk.svg"
                                    />
                            </div>
                        ))}

                        <div className="checkedBag">
                            <input id='checked' type="checkbox" checked={noCheckedBaggage} onChange={handleCheckBoxesChange2}/>
                            <label htmlFor="checked">No checked baggage</label>
                        </div>

                        <div className="insurancecont">
                           <div className="InsuranceHeader">Travel insurance</div>

                            <div className="subTextImg">
                                <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1703247775/starpenzu/Vector_jroanr.svg" alt=""/>
                                Applies to COVID-19 and related treatment.
                            </div>

                            <div className="insuranceRadio">

                                {insurance.map((ins, ins_index)=>(
                                    <div key={ins_index} className='insFlexx'>
                                        {ins.meta.options.map((op, op_index)=>(
                                            <div key={op_index} >
                                                <InsuranceRadio
                                                    accumulateDatafrominsure={accumulateDatafrominsure}
                                                    overallId={passangerIndex}
                                                    id={`in_${passangerIndex} ${op_index}`}
                                                    img='https://res.cloudinary.com/do5wu6ikf/image/upload/v1703247775/starpenzu/Label_SVG_du5gyx.svg'
                                                    travelPlan={`Travel ${capitalizeFirstLetter(op.level)}`}
                                                    insure={true}
                                                    price={setCurrrConv(currency, op.price.amount)}

                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))
                                }
                                <InsuranceRadio
                                    accumulateDatafrominsure={accumulateDatafrominsure}
                                    overallId={passangerIndex}
                                    id={`${passangerIndex}`}
                                    img='https://res.cloudinary.com/do5wu6ikf/image/upload/v1703247775/starpenzu/Label_SVG2_oagzf8.svg'
                                    travelPlan='No insurance'
                                    insure={false}
                                />

                            </div>
                        </div>




                        <div className="bookMorePass">
                            <div className="bookMorePassText">Booking for more passengers?</div>

                            <div className="bookMorePassBtn">
                                Add another passenger
                            </div>
                        </div>



                    </div>

                </div>
            )}

        </div>

    )

}