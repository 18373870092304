import React, {useEffect, useState} from 'react';
import BlueBack from "../SignUp/BlueBack";
import {Link, useParams} from "react-router-dom";
import InputField from "../InputField/InputField";
import lock from "../../images/lock.png"
import axiosWithAuth from "../Axios/axiosWithAuth";
import axios from "axios";
import Spinner from "../Spinner/Spinner";
import envVariables from "../../dev";

function PasswordReset() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [formValid, setFormValid] = useState(false);
    const [step, setStep] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const { uidb64, token } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const handleNext = () => {
        setStep(step + 1);
    };
    const validatePassword = () => {
        if (password.length < 8) {
            setPasswordError("Password must be at least 8 characters long.");
            setFormValid(false);
        } else if (!password.match(/[a-z]/)) {
            setPasswordError("Password must contain at least one lowercase letter.");
            setFormValid(false);
        } else if (!password.match(/[A-Z]/)) {
            setPasswordError("Password must contain at least one uppercase letter.");
            setFormValid(false);
        } else if (!password.match(/\d/)) {
            setPasswordError("Password must contain at least one number.");
            setFormValid(false);
        } else if (!password.match(/[@$!%*.?&]/)) {
            setPasswordError("Password must contain at least one special character.");
            setFormValid(false);
        } else if (password !== confirmPassword) {
            setPasswordError("Passwords do not match.");
            setFormValid(false);
        } else {
            setPasswordError("");
            setFormValid(true);
        }
    };
    useEffect(() => {
        validatePassword();
    }, [password, confirmPassword]);
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        validatePassword();
    };
    const apikey = envVariables.APIKEY;
    const handlePasswordSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {

            const response = await  axiosWithAuth(apikey).post(`/user/password-reset-confirm/${uidb64}/${token}/`, {
                password,
                password_confirm: confirmPassword,
            });

            const userData = response.data;
            if (response.status === 200 || response.status === 201) {
                setIsLoading(true);
                handleNext()

                window.location.href = '#/';
            } else {
                setErrorMessage('Invalid credentials. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
        finally{
            setIsLoading(false)
        }
    }
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        validatePassword();
    };

    function resendEmail() {

    }

    return (
        <div>
            {step === 1 && (
                <BlueBack containerClassName="PasswordReset">
                    <Link to="/login">
                        <img className='backButton'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678244681/arrow-left_u6g21o.png'
                             alt='cancelButton'/>
                    </Link>
                    <Link to="/signup">
                        <img className='cancelButton'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png'
                             alt='cancelButton'/>
                    </Link>

                    <div className='firstOne' >
                        Set New Password
                    </div>
                    <div className='secOne'>Your new password must be different from <br/>previously used passwords.</div>
                    <div className='img-con'>
                        <img id="passkey"
                             src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1679431422/Frame_3325_n1h2kr.svg"/>
                    </div>
                    <InputField classNameSg='sg' className="col-9 fi" name="Password" type="password"
                                placeholder="Password" onChange={handlePasswordChange} value={password}
                                required iconSrc={lock}/>
                    {passwordError &&
                        <div className='passwordDetails'>At least 8 characters long<br/>A mixture of both UPPERCASE
                            and lowercase<br/>Must contain numbers<br/>Must include at least one special character,
                            e.g .,!@#^?</div>}
                    {!passwordError && <div className='passwordDetails'></div>}
                    <InputField classNameSg='sg' className="col-9 fi" name="Confirm password" type="password"
                                placeholder="Confirm password" onChange={handleConfirmPasswordChange}
                                value={confirmPassword} required iconSrc={lock}/>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    <button type='submit' id="passResetButton" onClick={handlePasswordSubmit} className='stepButton'
                            disabled={!formValid}>{isLoading ? <Spinner /> : 'Reset Password'}
                    </button>
                </BlueBack>
            )}
            {step === 2 && (
                <BlueBack id='PasswordContinue' containerClassName="PasswordReset">
                    <Link to="/login">
                        <img className='backButton'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678244681/arrow-left_u6g21o.png'
                             alt='cancelButton'/>
                    </Link>
                    <Link to="/signup">
                        <img className='cancelButton'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png'
                             alt='cancelButton'/>
                    </Link>
                    <div id='passRight' className='img-con'>
                        <img id="passkey"
                             src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1679566416/Frame_3325_1_tb5a2n.svg"/>
                    </div>
                    <div className='firstOne' >
                        Password Reset
                    </div>
                    <div id='passRight_' className='secOne'>Your new password has been set successfully.  <br/>Click the button below to magically login.</div>

                    <button type='submit' id="passContinueButton" onClick={handlePasswordSubmit} className='stepButton'>Continue
                    </button>
                </BlueBack>
            )}
            {step === 3 && (
                <BlueBack id='PasswordEmail' containerClassName="PasswordReset">

                    <Link to="/signup">
                        <img className='cancelButton'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png'
                             alt='cancelButton'/>
                    </Link>
                    <div id='passRight' className='img-con'>
                        <img id="passkey"
                             src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1679567393/Frame_3325_1_otpbkn.svg"/>
                    </div>
                    <div className='firstOne' >
                        Check your Mail
                    </div>
                    <div id='passRight_' className='secOne'>We’ve sent a password recovery <br/>instruction to your email address.</div>
                    <Link to='https://mail.google.com/'>
                        <button type='submit' id="passEmailButton"  className='stepButton'>Open your email app
                        </button>
                    </Link>
                    <div id='emailNote' className='blueBack-fort toper'>Didn’t receive any email? <span onClick={resendEmail}><Link to ="/signup" >Resend link.</Link>
                    </span>. </div>
                    </BlueBack>
            )}
        </div>
    );
}

export default PasswordReset;
