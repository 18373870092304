import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../../index.css';


export default function TicketTabSkeleton(){
    return(
        <div>
            {<Skeleton height={200} width={200}/>}

        </div>
    )
}