import React, {useState} from "react";
import EconSeat from "../FlightSeatBooking/EconSeat";

export default function Testcomponent(){
    const [isScrollable, setIsScrollable] = useState(true);

    const handleToggleScroll = () => {
        setIsScrollable(!isScrollable);
    };

    return (
        <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <button onClick={handleToggleScroll}>
                {isScrollable ? 'Disable Scroll' : 'Enable Scroll'}
            </button>
            <div
                style={{
                    height: '100vh',
                    overflow: isScrollable ? 'auto' : 'hidden',
                }}
            >
                {/* Your page content goes here */}
                <h1>Your Page Content</h1>
                <p>More content...</p>
            </div>
        </div>
    );
}