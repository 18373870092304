import React from 'react';
import Header from "../navbar";

export default function BlueBack(props) {
    return (
        <div>
            <div id={props.id} className={props.containerClassName}>
                <div className='blueBack'>
                    <div>{props.children}</div>
                </div>
            </div>
        </div>

    );
}
