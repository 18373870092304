import axios from 'axios';

const axiosWithAuth =(apikey) =>  axios.create({
    baseURL: 'https://api.jaromtravels.com/api',
    headers: {
        Authorization: apikey||`Bearer ${localStorage.getItem('token')}`,
    },
});

export default axiosWithAuth;
