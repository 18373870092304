import React, {useContext, useEffect, useState} from "react";
import ReloadContext from "../Context/Reload";
import {  encrypt, decrypt  } from "../Security";

export default function Bags({ src, id1, idtext, id2, text, storageKey, handleSubmit, adult, children }) {
    const [value, setValue] = useState(0);
    const { reloadFlag , setReloadFlag } = useContext(ReloadContext);
    let stopper;
    let twiceStopper


    const handleIncrement = () => {
        adult = parseInt(localStorage.getItem('adults'), 10) || 0;
        children = parseInt(localStorage.getItem('children'), 10) || 0;
        stopper = adult + children;
        twiceStopper = 2 * stopper;
        if(storageKey === 'CabinBaggage'){
            if(value < stopper){
                const newValue = value + 1;
                setValue(newValue);
                localStorage.setItem(storageKey, encrypt(String(newValue)));
                console.log('string1', decrypt(localStorage.getItem('CabinBaggage')));
                handleSubmit();
            }
        }
        if(storageKey === 'CheckedBaggage'){
            if(value < twiceStopper){
                const newValue = value + 1;
                setValue(newValue);
                localStorage.setItem(storageKey, encrypt(String(newValue)));
                handleSubmit();
            }
        }
        reload();


    };
    const reload = () => {
        const baggage = parseInt(decrypt(localStorage.getItem('CabinBaggage')), 10)|| 0;
        const check = parseInt(decrypt(localStorage.getItem('CheckedBaggage')), 10)|| 0;
        adult = parseInt(localStorage.getItem('adults'), 10)|| 0;
        children = parseInt(localStorage.getItem('children'), 10)|| 0;
        stopper = adult + children;
        twiceStopper = 2 * stopper;
        if(storageKey === 'CabinBaggage') {
            if (baggage > stopper) {
                const newValue = stopper;
                setValue(newValue);
                localStorage.setItem(storageKey, encrypt(String(newValue)));
                handleSubmit();
            }
        }
        if(storageKey === 'CheckedBaggage') {
            if (check > twiceStopper) {
                const newValue = stopper;
                setValue(newValue);
                localStorage.setItem(storageKey, encrypt(String(newValue)));
                handleSubmit();
            }
        }
    }



    const handleDecrement = () => {
        if(value > 0){
            const newValue = value - 1;
            setValue(newValue);
            localStorage.setItem(storageKey, encrypt(String(newValue)));
            handleSubmit();
        }

    };

    useEffect(() => {
        if(localStorage.getItem(storageKey)){
            const storedValue = decrypt(localStorage.getItem(storageKey));
            if (storedValue !== null) {
                setValue(parseInt(storedValue));
            }
            if (reloadFlag) {
                reload()
                setReloadFlag(false)
            }
        }


    }, [reloadFlag]);

    return (
        <div>
            <div className="bags">
                <img src={src} alt="bag" />
                <div id={idtext}>{text}</div>
                <button onClick={handleDecrement} id={id1}>
                    -
                </button>
                <div className="pink">{value}</div>
                <button onClick={handleIncrement} id={id2}>
                    +
                </button>
            </div>
        </div>
    );
}
