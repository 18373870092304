import React, {useState} from "react";

export default  function InsuranceRadio({img, travelPlan, insure, price, id, overallId, accumulateDatafrominsure}){
    const [selectedOption, setSelectedOption] = useState('');

    function customStringify(obj) {
        const seen = new WeakSet();

        function replacer(key, value) {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return "[Circular Reference]";
                }
                seen.add(value);
            }
            return value;
        }

        return JSON.stringify(obj, replacer);
    }

    const set_to_local = (dataToStore, key_name) => {
        const data_stringify = customStringify(dataToStore);
        localStorage.setItem(key_name, data_stringify);
    }

    // Handler function to update the selected option
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        accumulateDatafrominsure({travelPlan: travelPlan, price: price}, overallId)
        set_to_local({travelPlan: travelPlan, price: price},  `insurancePerPassangers${overallId}`)
    };

    return(
        <div className='makeRes'>
            <div className="insuranceRadio1">
                <label className="radioReg">
                    <input type="radio" name={`insurance_${overallId}`} id={id} value={travelPlan}  onChange={handleOptionChange} />
                    <div className="labheadercont">
                        <img src={img} alt=""/>
                        <div className="labheader">
                            <div className="TravHeader">{travelPlan}</div>
                            <div className="insPrice"> {insure ? `+${price}` : ''}</div>
                        </div>
                    </div>
                </label>
            </div>
            {insure?
                <ul className="InsList">
                    <li>
                        <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1703247775/starpenzu/Vector_f_ddevcr.svg" alt=""/>
                        <div className="liTextIn">Medical expenses (including COVID-19).</div>
                    </li>
                    <li>
                        <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1703247775/starpenzu/Vector_f_ddevcr.svg" alt=""/>
                        <div className="liTextIn">
                            Trip cancellation due to your illness
                            (incl. COVID-19), accident, death.
                        </div>
                    </li>
                    <li>
                        <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1703247775/starpenzu/Vector_f_ddevcr.svg" alt=""/>
                        <div className="liTextIn">Assistance services.</div>
                    </li>

                    <li>
                        <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1703247775/starpenzu/Vector_f_ddevcr.svg" alt=""/>
                        <div className="liTextIn">Lost baggage.</div>
                    </li>

                    <li>
                        <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1703247775/starpenzu/Vector_f_ddevcr.svg" alt=""/>
                        <div className="liTextIn">Air travel insurance.</div>
                    </li>

                    <li>
                        <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1703247775/starpenzu/Vector_f_ddevcr.svg" alt=""/>
                        <div className="liTextIn">Liability.</div>
                    </li>
                </ul>
                :
                ''
            }
        </div>
    )
}