import React, {useEffect, useState} from 'react';
import './FlightSeatArrg.css';
import PlaneSeat from "./PlaneSeat";
import QuickOption from "./QuickOption";
import NoSeatMapandQuickOpt from "./NoSeatMapandQuickOpt";
import Skeleton from "react-loading-skeleton";


export default function FlightSeatArrg({ offers,mainRoute, offersIndex, route, passengerData, generateSeatingSummary, handle_return, passengerName, passIndex, remove_return, genarateQuickOptionSummary }) {
    const [loading, setLoading] = useState(()=> true);

    function checkLocalStorageValue (){
        if( localStorage.getItem("status_") === "complete"){
            setLoading(false);
        }else{
            setLoading(true)
        }
    }

    setInterval(checkLocalStorageValue, 500);


    function check_offer(x){
        if ((x !== null || undefined) ||(x.seatmap !== null || undefined) || (x.quick_options !== null || undefined)) {
            // Handle the case when 'offers' or 'seatmap' is not available
            return  x;
            // <NoSeatMapandQuickOpt offersIndex={offersIndex} route={route} mainRoute={mainRoute} />
        }else{
           return <div>bad</div>

        }
    }




    const offer = check_offer(offers)

    const seatmap = offer.seatmap;


    const check_seatmap = (x) => {
        if (x !== null && x !== undefined) {
            return x.sections[0].rows;
        } else {
            return null;
        }
    }

    const seating = check_seatmap (seatmap);
    // console.log(seating, 'seating')

    const quick_option = offer.quick_options;

    function check_quick_opt(x) {
        if (x !== null && x !== undefined) {
            return x;
        } else {
            return null;
        }
    }

    const main_quick_option = check_quick_opt(quick_option);


    const updateSeatingData = (seating, dummyBoxName = "Dummy Box") => {
        if (!seating) {
            // console.log("Seating data is undefined or null.");
            return null; // Return the original data when it's missing
        }

        let maxSeatCount = 0;

        for (let i = 0; i < seating.length; i++) {
            const seat = seating[i].seat_groups;

            if (!seat) {
                seating[i].seat_groups = [missingSeatGroup(0, dummyBoxName)]; // Pass 0 as expectedSeatCount
            } else {
                for (let j = 0; j < seat.length; j++) {
                    if (seat[j].length === 0) {
                        seat[j].push({ seat: dummyBoxName });
                    }
                    if (seat[j].length > maxSeatCount) {
                        maxSeatCount = seat[j].length;
                    }
                }
            }
        }

        for (let i = 0; i < seating.length; i++) {
            const seat = seating[i].seat_groups;
            if (seat) {
                for (let j = 0; j < seat.length; j++) {
                    if (seat[j].length < maxSeatCount) {
                        const additionalSeats = maxSeatCount - seat[j].length;
                        for (let k = 0; k < additionalSeats; k++) {
                            seat[j].push({ seat: "Additional Seat" });
                        }
                    }
                }
            }
        }

        return seating;
    }

    // Helper function to create a seat group with the specified number of dummy boxes
    function missingSeatGroup(expectedSeatCount, dummyBoxName) {
        return Array.from({ length: expectedSeatCount }, () => ({ seat: dummyBoxName }));
    }

    const updatedSeatingData = updateSeatingData(seating, "Empty Seat");
    // console.log(updatedSeatingData);









    if (loading) {
        return <div><Skeleton width={400} height={70}/></div>;
    }
    else if (offer && offer.seatmap && offer.seatmap.sections.length > 0) {
        return (
            <>
                {offer.seatmap ?
                    <div key={offersIndex}>
                        <PlaneSeat
                            passIndex={passIndex}
                            generateSeatingSummary={generateSeatingSummary}
                            route={route}
                            mainRoute={mainRoute}
                            seating={seating}
                            passengerData={passengerData}
                            passengerName={passengerName}
                            checkcoloum={updatedSeatingData}
                            offersIndex={offersIndex}
                            offers={offer}
                            handle_return={handle_return}
                            remove_return={remove_return}
                        />
                    </div> :

                    ''
                }
            </>
        )

    }
    else if (offer.quick_options && offer.quick_options.length > 0) {
        return (
            <>
                {offer.quick_options ?
                    <div key={offersIndex}>
                        <QuickOption
                            route={route}
                            mainRoute={mainRoute}
                            genarateQuickOptionSummary={genarateQuickOptionSummary}
                            quickoption={main_quick_option}
                            offers={offer}
                            handle_return={handle_return}
                            offersIndex={offersIndex}
                        />
                    </div>
                    :
                    ''

                }
            </>
        )

    } else {
        return (
            <NoSeatMapandQuickOpt offersIndex={offersIndex} mainRoute={mainRoute} offers={offer} route={route} />
        )
    }
}
