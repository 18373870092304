import React from 'react'

export default function SeatClass({ecoSeatImg, EcoHeadText, TrFa , ecoSubText, listImg, classList, lineColour}){
    return(
        <div className="ecoCont">
            <div className="ecoSeatImg">
                <img src={ecoSeatImg} alt=""/>
            </div>

            <div className="EcoHeadCont">
                <div className="EcoHeadText">{EcoHeadText}</div>
                {TrFa &&<div className="EcoSelect">
                    Selected
                </div>}
            </div>



            <div className="ecoSubText">
                {ecoSubText}
            </div>
            <div className={`${lineColour}`}>

            </div>


            <div className="featuresListsCont">

                <ul className="featuresLists">
                    {classList.map((item, index) =>
                    <li key={index}>
                        <img src={listImg} alt="" className="blueDot"/>
                        <div className="listsText">{item}</div>
                    </li> )}

                    {/*<li>*/}
                    {/*    <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691435557/starpenzu/point_heavy_oiqszh.svg" alt="" className="blueDot"/>*/}
                    {/*    <div className="listsText">Complimentary snacks and drinks{listsText2}</div>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*    <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691435557/starpenzu/point_heavy_oiqszh.svg" alt="" className="blueDot"/>*/}
                    {/*    <div className="listsText">One free carry-on and personal item{listsText3}</div>*/}
                    {/*</li>*/}
                </ul>
            </div>

        </div>

    )
}