import React, { useState } from 'react';
import VisaInputField from "./visaInput";


const MultipleFileInputs = ({heading}) => {
    const [fileInputs, setFileInputs] = useState([{ id: 1, value: null }]);

    const handleAddFileInput = () => {
        const newId = fileInputs.length + 1;
        setFileInputs([...fileInputs, { id: newId, value: null }]);
    };

    const handleFileInputChange = (id, file) => {
        const updatedFileInputs = fileInputs.map((input) => {
            if (input.id === id) {
                return { id: input.id, value: file };
            }
            return input;
        });
        setFileInputs(updatedFileInputs);
    };

    return (
        <div className='multiple'>
            {fileInputs.map((input) => (
                <VisaInputField
                    key={input.id}
                    labelName={`${heading} ${input.id}`}
                    star="*"
                    type="file"
                    placeholder=""
                    required={true}
                    localStorageName={`${heading} ${input.id}`}
                    onChange={(e) => handleFileInputChange(input.id, e.target.files[0])}
                />
            ))}
            <button className='visa_multiple' onClick={handleAddFileInput}>Add Data Page &nbsp;&nbsp; +</button><br/>
        </div>
    );
};

export default MultipleFileInputs;
