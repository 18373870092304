import React, { useState, useEffect } from 'react';

const RadioInput = ({ text, localStorageName, onClick }) => {
    const [color, setColor] = useState('');

    useEffect(() => {
        const storedColor = localStorage.getItem(localStorageName);
        if (storedColor==='Yes') {
            setColor('blue');
        }
        else if (storedColor==='No') {
            setColor('red');
        }
    }, [localStorageName]);

    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setColor(newColor);
        if (newColor==='blue') {
            localStorage.setItem(localStorageName, 'Yes');
        }
        else if (newColor==='red') {
            localStorage.setItem(localStorageName, 'No');
        }


    };

    return (
        <>
            <div>
                <div className='visa_applicant' id='visa_topper'>
                    {text}<span>*</span>
                </div>
                <div className='visa_choice' onInput={onClick}>
                    <input
                        type="radio"
                        name={localStorageName}
                        value="blue"
                        checked={color === "blue"}
                        onChange={handleColorChange}
                    />
                    <label htmlFor="blue" id='visa_rad'>Yes</label>

                    <input
                        type="radio"
                        name={localStorageName}
                        value="red"
                        checked={color === "red"}
                        onChange={handleColorChange}
                    />
                    <label htmlFor="red">No</label>
                </div>
            </div>

        </>
    );
};

export default RadioInput;
