import React, {useEffect, useState} from "react";
import './BookingPage.css'
import MoreInfo from "../FlightSearch/MoreInfo";
import MainContSSF from "./mainContSSF";
import SeatClass from "./SeatClass";
import FlightSeatArrg from "../FlightSeatBooking/FlightSeatArrg";
import axios from "axios";
import {decrypt} from "../Security";
import DuplicatePassengers from "./duplicatePassengers";
import {Link} from "react-router-dom";
import {countries} from "./countries";
import QuickOption from "../FlightSeatBooking/QuickOption";
import MoreInfoSkeleton from "../SkeletonLoadingComponent/BookingSkeleton/MoreInfoSkeleton";
import countryData from '../FlightSearch/countryData';
import PaystackComponent from "./paymentGateway";
import envVariables from "../../dev";
import Baggage from "./Baggage";
import GaranteeUI from "./garanteeUI";
import {setresdummy} from "./setresdummy";



export default function BookingPage() {
    const [hasRunOnce, setHasRunOnce] = useState(false);
    const [hasRunOnce_, setHasRunOnce_] = useState(false);
    const [Dropdown, setDropdown] = useState(false);
    const [priceChange, setPriceChange] = useState(false);
    const [price, setPrice] = useState(false);
    const [index, setIndex] = useState([]);
    const [status, setStatus] = useState("");
    const [Response, setResponse] = useState([]);
    const [flightInvalid, setFlightInvalid] = useState(false);
    const [cabinBaggage, setCabinBaggage] = useState([]);
    let seating_ = []
    // let ReturnRes = [];
    const [cabinHandBaggage, setHandCabinBaggage] = useState([]);
    const [cabinHandBaggageDetails, setHandCabinBaggageDetails] = useState([]);
    const [cabinBaggageDetails, setCabinBaggageDetails] = useState([]);
    const [termAndCondition, setTermAndCondition] = useState(false);
    const [seatMap, setSeatMap] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [returnres, setReturnRes] = useState([]);
    const [uniqueList, setUniqueList] = useState([]);
    const [priceForservice, setPriceForservice] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [garaList, setGaraList] = useState([]);


    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = '#/SearchResult';
            console.log("30 minutes have passed! correct the link in PROD");
        }, 30 * 60 * 1000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handleCheckBoxesChange = (event) =>{
        setTermAndCondition(event.target.checked)

    }



    localStorage.setItem('TermsAndCondition', termAndCondition);

    const [bookingLoading, setBookingLoading] =useState(true)
    const getAPIKEY =()=>{
        const trip = localStorage.getItem('Trip')||'One Way'
        let apikey
        if (trip === 'One Way'){
            apikey = envVariables.APIKEY_RETURN_ONEWAY;
        }
        else{
            apikey = envVariables.APIKEY_MULTICITY;
        }
        return apikey
    }

    let apiKey = getAPIKEY()
    const token = decrypt(localStorage.getItem('bookingToken'));
    const sessionID = localStorage.getItem('bluk')||'';
    const checkFlight = ()=>{
        handleApiRequest()
        const check = parseInt(decrypt(localStorage.getItem('CheckedBaggage')), 10);
        const adult = localStorage.getItem('adults');
        const children = localStorage.getItem('children');
        const infant = localStorage.getItem('infant');
        const params = {
            booking_token: token,
            bnum: check,
            adults: adult,
            children: children,
            infants: infant,
            session_id: sessionID,
            currency: localStorage.getItem('Currency')||'',
        }
        axios
            .get(`https://api.tequila.kiwi.com/v3/booking/check_flights`, {
                params: params,
                headers: {
                    "accept": "application/json",
                    "apikey": apiKey
                }
            })
            .then((response) => {
                if (!hasRunOnce) {
                    handleApiRequest()
                    localStorage.setItem('bluk', response.data.session_id);
                    setHasRunOnce(true);
                }
                set_to_local(response.data, 'booking_response_data');
                // console.log('booking set to local ran')
                setPriceChange(response.data.price_change);
                setFlightInvalid(response.data.flights_invalid)
                setPrice(response.data.conversion.amount);
                setPriceForservice(response.data.conversion.amount);
                const rate = parseInt(response.data.conversion.amount)/parseInt(response.data.total);
                localStorage.setItem('rate',rate);
                const rawBaggagePrice = response.data.baggage.definitions.hold_bag;
                const amountList = rawBaggagePrice
                    .map(entry => entry.price.amount);
                const actualCabinBaggage = amountList.map(amount => Number((amount* rate)).toLocaleString('en-NG', { maximumFractionDigits: 2 }));
                setCabinBaggage(actualCabinBaggage);
                const ListDetails = rawBaggagePrice
                    .map(entry => entry.restrictions);
                setCabinBaggageDetails(ListDetails);
                const rawHandBaggagePrice = response.data.baggage.definitions.hand_bag;
                const amountHandList = rawHandBaggagePrice
                    .map(entry => entry.price.amount);
                const actualHandCabinBaggage = amountHandList.map(amount => Number((amount* rate)).toLocaleString('en-NG', { maximumFractionDigits: 2 }));
                let actualMerge = []
                if(actualHandCabinBaggage.length > 1){
                    actualMerge = [
                        actualHandCabinBaggage[0],
                       [ ...actualHandCabinBaggage.slice(1)]
                    ]
                }
                else {
                    actualMerge = actualHandCabinBaggage
                }
                setHandCabinBaggage(actualMerge);
                const amountHandListDetails = rawHandBaggagePrice
                    .map(entry => entry.restrictions);
                const actualHandCabinBaggageDetails = amountHandListDetails;
                let actualMergeDetails = []
                if(actualHandCabinBaggageDetails.length > 1){
                    actualMergeDetails = [
                        actualHandCabinBaggageDetails[0],
                        [...actualHandCabinBaggageDetails.slice(1)]
                    ];
                }
                else {
                    actualMergeDetails = actualHandCabinBaggageDetails

                }
                setHandCabinBaggageDetails(actualMergeDetails);
                if(!hasRunOnce){
                    setBookingLoading(false)
                }

            })
            .catch((error) => {
                console.error("Error fetching countries brs:", error);
                if(error.request.status === 429){
                    const bookingResponseFromLocal = get_from_local('booking_response_data');
                    setPriceChange(bookingResponseFromLocal.price_change);
                    setFlightInvalid(bookingResponseFromLocal.flights_invalid)
                    setPrice(bookingResponseFromLocal.conversion.amount);
                    setPriceForservice(bookingResponseFromLocal.conversion.amount);
                    const rate = parseInt(bookingResponseFromLocal.conversion.amount)/parseInt(bookingResponseFromLocal.total);
                    const rawBaggagePrice = bookingResponseFromLocal.baggage.definitions.hold_bag;
                    const amountList = rawBaggagePrice
                        .map(entry => entry.price.amount);
                    const actualCabinBaggage = amountList.map(amount => Number((amount* rate)).toLocaleString('en-NG', { maximumFractionDigits: 2 }));
                    setCabinBaggage(actualCabinBaggage);
                    const ListDetails = rawBaggagePrice
                        .map(entry => entry.restrictions);
                    setCabinBaggageDetails(ListDetails);
                    const rawHandBaggagePrice =bookingResponseFromLocal.baggage.definitions.hand_bag;
                    const amountHandList = rawHandBaggagePrice
                        .map(entry => entry.price.amount);
                    const actualHandCabinBaggage = amountHandList.map(amount => Number((amount* rate)).toLocaleString('en-NG', { maximumFractionDigits: 2 }));
                    let actualMerge = []
                    if(actualHandCabinBaggage.length > 1){
                        actualMerge = [actualHandCabinBaggage[0],[actualHandCabinBaggage[1],actualHandCabinBaggage[2]]]
                    }
                    else {
                        actualMerge = actualHandCabinBaggage
                    }
                    setHandCabinBaggage(actualMerge);
                    const amountHandListDetails = rawHandBaggagePrice
                        .map(entry => entry.restrictions);
                    const actualHandCabinBaggageDetails = amountHandListDetails;
                    let actualMergeDetails = []
                    if(actualHandCabinBaggageDetails.length > 1){
                        actualMergeDetails = [actualHandCabinBaggageDetails[0],[actualHandCabinBaggageDetails[1],actualHandCabinBaggageDetails[2]]]
                    }
                    else {
                        actualMergeDetails = actualHandCabinBaggageDetails
                    }
                    setHandCabinBaggageDetails(actualMergeDetails);
                }

                if(!hasRunOnce){
                    setBookingLoading(false)
                }

                // alert('Booking res from LocalStorage');

            });

    }
    useEffect(() => {
        const timer = setTimeout(() => {
            checkFlight()
        }, 5 * 1000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(()=>{
        if (!hasRunOnce_) {
            checkFlight()
            setHasRunOnce_(true);
        }
    },[hasRunOnce_]);

    const adultCount = localStorage.getItem('adults');
    const childCount = localStorage.getItem('children');
    const infantCount = localStorage.getItem('infant');
    const passengerData = [];
    for (let i = 0; i < adultCount; i++) {
        if(i===0){
            passengerData.push({ name: 'Adult',  state: 'Primary' });
        }
        else{
            passengerData.push({ name: 'Adult', state: 'Secondary' });
        }
    }

    for (let i = 0; i < childCount; i++) {
        passengerData.push({ name: 'Child', state: 'Secondary' });
    }
    for (let i = 0; i < infantCount; i++) {
        passengerData.push({ name: 'Infant', state: 'Secondary' });
    }


    const[page, setPage] = useState(2);
    const[seatPage, setSeatPage] = useState(0);

    const gender = ['Male', 'Female', 'Others'];
    const businessClass = [
        'Extended leg room',
        'First two checked bags free',
        'Priority boarding',
        'Personalized service',
        'Enhanced food and drink service',
        'Seats that recline 40% more than economy'
    ];

    const EconomyClass = [
        'Built-in entertainment system',
        'Complimentary snacks and drinks',
        'One free carry-on and personal item'
    ];



    const currency = localStorage.getItem('Currency');

    let currSign = ''
    const setCurrrConv = (curr, priceToconvert)=>{
        let price_;
        if (curr === 'NGN') {
            price_ = Number(priceToconvert).toLocaleString('en-NG', { maximumFractionDigits: 2 });
            currSign = '₦'
           return `₦${price_}`
        }
        else if (curr === 'USD') {
            price_ = Number(priceToconvert).toLocaleString('en-US', { maximumFractionDigits: 2 });
            currSign ='$'
            return `$${price_}`
        }
        else if (curr === 'EUR') {
            price_ = Number(priceToconvert).toLocaleString('en-GB', { maximumFractionDigits: 2 });
            currSign='€'
            return `€${price_}`
        }
    }

    const myPrice = setCurrrConv(currency, price)
    localStorage.setItem('base_fare', myPrice)
    const myPricenum = parseFloat(myPrice.replace(/[^0-9.-]/g, ''));

    const myDuration = localStorage.getItem('duration');
    const myKey = localStorage.getItem('moreInfoKey');
    const myRoute = JSON.parse(localStorage.getItem('route'));

    const discount = true;
    const curr = localStorage.getItem("Currency");

    const indices = [];

    for (let i = 0; i < passengerData.length; i++) {
        if (passengerData[i].name !== 'infant') {
            indices.push(i);
        }
    }
// Function to convert a list of country names to two-letter country codes
    function convertCountryNamesToCodes(names) {
        return names.map(name => {
            const country = countryData.find(country => country.Name === name);
            return country ? country.Code : "Not found";
        });
    }
    const passengers =() => {
        const birthList = [];
        const countryList =[];
        const passNum=[];
        const emailList=[];
        const expList =[];
        const titleList =[];
        const firstNameList =[];
        const surnameList =[];
        const phoneList =[];
        let categoryList = passengerData.map(item => item.name);
        for (let i = 0; i < passengerData.length; i++) {
            const item = localStorage.getItem(`dateOOfBirthBookingPage${i}`);
            const item1 = localStorage.getItem(`NationalityBookingPage${i}`);
            const item2 = localStorage.getItem(`PassportNumberBookingPage${i}`);
            const item3 = localStorage.getItem(`EmailBookingPage${i}`);
            const item4 = localStorage.getItem(`PassportExpiryDateBookingPage${i}`);
            const item5 = localStorage.getItem(`FirstNameTitleBookingPage${i}`) ||'Mr';
            const item6 = localStorage.getItem(`FirstNamebookingPage${i}`);
            const item7 = localStorage.getItem(`SurnameBookingPage${i}`);
            const item8 = localStorage.getItem(`homePhoneNumberBookingPage${i}`);

            birthList.push(item);
            countryList.push(item1);
            passNum.push(item2);
            emailList.push(item3);
            expList.push(item4);
            titleList.push(item5.toLowerCase());
            firstNameList.push(item6);
            surnameList.push(item7);
            phoneList.push(item8);

        }
        let countryCodesList = convertCountryNamesToCodes(countryList);
        categoryList = categoryList.map(item => item.toLowerCase());
        countryCodesList = countryCodesList.map(item => item.toLowerCase());
        const bookingList = birthList.map((birthday, index) => ({
            birthday,
            cardno: passNum[index],
            category: categoryList[index],
            currency: curr,
            email: emailList[index],
            expiration: expList[index],
            title: titleList[index],
            name: firstNameList[index],
            surname: surnameList[index],
            nationality: countryCodesList[index],
            phone: phoneList[index],
        }));
        // console.log(bookingList,'love')

        localStorage.setItem('passBookingList',  JSON.stringify(bookingList));

        const mergedList = birthList.map((birthday, index) => ({
            birthday,
            category: categoryList[index],
            nationality: countryCodesList[index],
        }));
        return mergedList
    }

    const PassengerName =() => {
        const first_name = [];
        const sec_name = [];
        for (let i = 0; i < passengerData.length; i++) {
            const item = localStorage.getItem(`FirstNamebookingPage${i}`);
            const item2 = localStorage.getItem(`MiddleNamebookingPage${i}`);
            first_name.push(item);
            sec_name.push(item2);

        }
        const names = passengerData.map((birthday, index) => ({
            id: index,
            firstName: first_name[index],
            secName: sec_name[index],
        }));
        return names;
    }


    const PassengerNameForSeat = PassengerName();


    function makeApiRequest(bookingToken, sessionId, currency) {
        const postData = {
            ancillaries: ["seating","priority_boarding","guarantee","axa_insurance","axa_us_insurance","fare_type","service_package"],
            booking_token: bookingToken,
            currency: currency,
            passengers: passengers(),
            session_id: sessionId
        };

        const headers = {
            'Content-Type': 'application/json',
            'apikey': apiKey
        };

        return axios.post('https://api.tequila.kiwi.com/v2/booking/ancillaries/offers/check', postData, { headers });
    }



    const styles = {
        display: 'none'
    };

    const styles2 = {
        position: 'unset'
    };


    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    // const seatData = setresdummy;

    const set_to_local = (dataToStore, key_name) =>{
        const full_response = dataToStore;
        const data_stringify = JSON.stringify(full_response);
        localStorage.setItem(`${key_name}`, data_stringify)
        // console.log(`${key_name}, saved in local storage`)

    }

    const get_from_local = (key_name) =>{
            const data_stringify = localStorage.getItem(`${key_name}`);
            if(data_stringify){
                const full_response = JSON.parse(data_stringify)
                // console.log(`${key_name},found in local storage`)
                return full_response
            }else{
                // console.log(`${key_name}, not found in local storage`)
            }
    }

    set_to_local(passengers(), 'passdata')



    const [Error, setError] = useState(null);

    const handleApiRequest = () => {
        makeApiRequest(token, sessionID, curr)
            .then(response => {
                if(response && Error !== 429){
                    // console.log(response, 'res sta');
                    set_to_local(response.data, 'response_data');
                    // console.log('set to local ran')
                     if (response.status === 200){
                        const seatStatus = response.data;
                        setStatus(seatStatus);
                        setResponse(seatStatus);
                        // console.log(seatStatus, 'ds')

                        // alert('Res from API')
                        setError(null);
                    }else{
                        // console.log('response not 200 or 429')
                    }


                }

            })
            .catch(error => {
                setError(error.request.status);
                // console.log(Error, 'testx')
                if(error.request.status === 429){
                    const responseFromLocal = get_from_local('response_data');
                    // console.log('get from local ran')
                    const seatStatus = responseFromLocal;
                    setStatus(seatStatus);
                    // alert('Res from LocalStorage')
                }
                setResponse(null);
            });
    };


    if(status !== ''){
        const realStatus = status.seating.status;
        if(realStatus !== 'complete'){
            localStorage.setItem("status_","loading")
            // packages will be here
            // setIsLoading(true);
            setTimeout(() => {
                handleApiRequest();
            }, 10 * 1000);
            // console.log("Not completed");
        }
        else {
            localStorage.setItem("status_","complete")
            // console.log("Its completed");

        }
    }
    const status_ = localStorage.getItem("status_,")

    const clearState = () => {
        setReturnRes([]);
        setIndex([]);
    };
    const clearLocalStorageForComponent = () => {
        localStorage.removeItem('SeatingSummary')
        localStorage.removeItem('updatedSeat')
        localStorage.removeItem('myIndex')

        for (let i = 0; i < passengerData.length; i++) {
            for (let j = 0; j < mainRoute.length; j++) {
                const key1 = `seatmapData ${j} ${i}`;
                const key2 = `selectedSeat seatMapIndex ${j} ${i}`;
                const key3 =`seatDictionary${j}`
                const key4 =`updatedSeat${j}`
                localStorage.removeItem(key1);
                localStorage.removeItem(key2);
                localStorage.removeItem(key3);
                localStorage.removeItem(key4);
            }
        }

        console.clear();
    }

    const NextFunc = () =>{
        setPage(page + 1)
        // console.log(page)
        if(page === 2 ){
            clearLocalStorageForComponent();
            clearState();
            // handleApiRequest();
        }
    }

    const PrevFunc = () =>{
        if(page < 4){
            setPage(page - 1);
        }else{
        }
    }


    const previousComponent = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } else {
            setPage(page - 1);
        }
    };

    const nextComponent = () => {
        console.log("Reached the end.");
        setPage(page + 1);
    };
    const PrevSeatPage = ()=>{

        if(seatPage === 0){
            // clearLocalStorageForComponent();
            PrevFunc();
        }
        if(page === 3){
            previousComponent();
        }

        if(page === 4){
            previousComponent();
        }
    }
    const NextSeatPage = ()=>{
        console.log(page, "seatpage")
        if(seatPage === 2){
            NextFunc();
        }
        if(page === 3){
            nextComponent();
        }
        else{
            setSeatPage(seatPage + 1)
            // console.log('Seat Page ' + seatPage)
        }
    }

    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedBaggagePerPassenger, setSelectedBaggagePerPassenger] = useState(Array(passengerData.length).fill([]));
    const handleBaggageSelection = (baggageInfo, passengerIndex) => {
        setSelectedBaggagePerPassenger(prevSelected => {
            const updatedSelected = [...prevSelected];
            updatedSelected[passengerIndex] = prevSelected[passengerIndex].concat(baggageInfo);
            return updatedSelected;
        });

        setTotalPrice((prevTotal) => prevTotal + parseFloat(baggageInfo.priceBag.replace(/[^0-9.-]/g, '')));
    }


    const handleBaggageDeselection = (baggageInfo, passengerIndex) => {
        setSelectedBaggagePerPassenger(prevSelected => {
            const updatedSelected = [...prevSelected];
            updatedSelected[passengerIndex] = prevSelected[passengerIndex].filter(baggage => baggage.item !== baggageInfo.item);
            return updatedSelected;
        });

        setTotalPrice((prevTotal) => prevTotal - parseFloat(baggageInfo.priceBag.replace(/[^0-9.-]/g, '')));

    };

    const isBaggageSelected = (itemName, passengerIndex) => {
        return selectedBaggagePerPassenger[passengerIndex].some(
            (baggage) => baggage.item === itemName
        );
    };

    const [selectedClass, setSelectedClass] = useState(null);
    const [totalPricecu, setTotalPricecu] = useState(0);

    const handleClassSelect = (classInfo) => {
        if (!classInfo) {
            return;
        }

        if (selectedClass === classInfo) {
            setSelectedClass(null);
            setTotalPricecu((prevTotal) => prevTotal - parsePrice(classInfo));
        } else {
            if (selectedClass) {
                setTotalPricecu((prevTotal) => prevTotal - parsePrice(selectedClass));
            }
            setSelectedClass(classInfo);
            setTotalPricecu((prevTotal) => prevTotal + parsePrice(classInfo));
        }
    };

    const [selectedClass2, setSelectedClass2] = useState(null);
    const handleClassSelect2 = (classInfo) => {
        if (!classInfo) {

            return;
        }

        if (selectedClass2 === classInfo) {
            setSelectedClass2(null);
        } else {
            if (selectedClass2) {
                // setTotalPricecu((prevTotal) => prevTotal - parsePrice(selectedClass2));
            }
            setSelectedClass2(classInfo);
        }
    };



    function isSelected(selectedClass, classInfo) {
        const capitalizeFirstChar = (str) => {
            return str.charAt(0).toUpperCase() + str.slice(1);
        };

        const refined =  capitalizeFirstChar(classInfo.ticket);
        if (selectedClass && classInfo) {
            return selectedClass === refined;
        }
        return false;
    }

    const planeMap = get_from_local('response_data') ? get_from_local('response_data') : []
    const TicketType = planeMap.fare_type ? planeMap.fare_type: [];
    const finalTic = TicketType.offers ? TicketType.offers : [];
    const insurance__ = planeMap.axa_insurance ? planeMap.axa_insurance  : [];
    const insurance = insurance__.offers ? insurance__.offers  : [];
    const guarantee__ = planeMap.guarantee ? planeMap.guarantee : [];
    const guarantee = guarantee__.offers ? guarantee__.offers : [];
    if(guarantee.length === 1){
        guarantee.push({ id: 'dummy' });
    }


    const route = JSON.parse(localStorage.getItem('updatedRoute'));

    const check_route=(x)=>{
        if(x !== ''){
            return x
        }else{
            return null

        }
    }

    const mainRoute = check_route(route);
    // let SeatingSummary = [];
    const Retrive = ()=>{
        if(!get_from_local('SeatingSummary')){
            return [];
        }else{
            return get_from_local('SeatingSummary')
        }
    }

    function doesSeatExist(name, array) {
        return array.some(item => item.seat.name === name);
    }

    function generateSeatingSummary(seatToSummary, index) {
        let myRetData = Retrive()
        const seatCode = seatToSummary.seat.name
        const seatExist = doesSeatExist(seatCode, myRetData)
        let updatedDataRet = myRetData;
        if(seatExist){
          updatedDataRet = myRetData.filter(item => item.seat.name !== seatCode);

        }
        updatedDataRet.push(seatToSummary)

        set_to_local(updatedDataRet, 'SeatingSummary')
        return updatedDataRet;
    }

    const genarateQuickOptionSummary = (quickToSeat, seatMapIndex) => {
        setIndex((prevIndex) => {
            const updatedDataList = [...prevIndex];
            const existingDataIndex = updatedDataList.findIndex(
                (item) => item.seatmapid === seatMapIndex
            );

            const {
                seatmapid,
                cityTo,
                cityFrom,
                cityCodeTo,
                cityCodeFrom,
                price,
                passengerIndex,
                intPrice,
                seat,
            } = quickToSeat;

            const formattedSeatData = {
                seatmapid,
                cityTo,
                cityFrom,
                cityCodeTo,
                cityCodeFrom,
                price,
                passengerIndex,
                intPrice,
                seat,
            };

            // If data for the same seatMapIndex already exists, replace it
            if (existingDataIndex !== -1) {
                updatedDataList[existingDataIndex] = formattedSeatData;
            } else {
                // Otherwise, add new data for the seatMapIndex
                updatedDataList.push(formattedSeatData);
            }

            return updatedDataList;
        });
    };

    function handle_return(filteredArray){
        setReturnRes(filteredArray);
    }


    const remove_return =(index, seatMapIndex)=>{
        const updatedPassengers = returnres.filter(
            (passenger) =>
                !(passenger.passengerIndex === index && passenger.seatmapid === seatMapIndex)
        );
        setReturnRes(updatedPassengers);
        set_to_local(updatedPassengers, 'SeatingSummary');
    }





    const seatPriceExtraction = returnres.map(price=>{
        return price.intPrice
    })
    const totalSeatPrice = seatPriceExtraction.reduce((acc, currentValue) => acc + currentValue, 0);

   const quickOptionPrice = index.map(priceOp =>{
       return priceOp.intPrice
   })

    const totalSeatPriceQO = quickOptionPrice.reduce((acc, currentValue) => acc + currentValue, 0);





    const serviceCharge = (priceToConvert)=>{
        return 0.05 * priceToConvert;
    }

    const myServiceCharge = serviceCharge(priceForservice);

    const servicePriceToDisplay = setCurrrConv(currency, myServiceCharge)
    localStorage.setItem('service_charge', servicePriceToDisplay)


    const parsePrice = (price) => {
        // Remove currency symbols and commas from the price string
        const cleanedPrice = price.replace(/[$,₦£]/g, '');

        // Parse the cleaned price to a float
        return parseFloat(cleanedPrice);
    };

    const insurancePrice = dataList.map(insureprice =>{
        return insureprice.price ? parsePrice(insureprice.price) : 0
    })

    const garanteePrice = garaList.map(garaprice =>{
        return garaprice.price ? parsePrice(garaprice.price) : 0
    })

    const totalinsurancePrice = insurancePrice.reduce((acc, currentValue) => acc + currentValue, 0);

    const totalGaraPrice = garanteePrice.reduce((acc, currentValue) => acc + currentValue, 0);


    const finalPrice = myPricenum + totalPrice + totalPricecu + myServiceCharge + totalSeatPrice + totalSeatPriceQO + totalinsurancePrice + totalGaraPrice;

    const mainPrice = Math.ceil(finalPrice * 100) / 100;

    const formatNumberWithCommas = (number) => {
        return number.toLocaleString(undefined, { maximumFractionDigits: 3 });
    };

    const stringMainPrice = formatNumberWithCommas(mainPrice);

    // console.log(typeof mainPrice, mainPrice, 'mainPrice');
    // console.log(typeof(seatPrice),seatPrice, 'seatPrice');

    const handleSeatSumDropDown = ()=>{
        setDropdown(!Dropdown)
    }

    const reciveFromEcon=(index)=>{
        // console.log(index, 'i')
        return index
    }



    const accumulateDatafrominsure = (insuranceData, passengerIndex) => {
        // Check if data with the same passengerIndex already exists
        const existingIndex = dataList.findIndex(entry => entry.passengerIndex === passengerIndex);

        if (existingIndex !== -1) {
            // If the same passengerIndex exists, override the existing entry
            const updatedList = [...dataList];
            updatedList[existingIndex] = { ...insuranceData, passengerIndex };
            setDataList(updatedList);
        } else {
            // If the passengerIndex doesn't exist, add a new entry to the list
            setDataList(prevList => [...prevList, { ...insuranceData, passengerIndex }]);
        }
    };

    const accumulateDatafromGarantee = (garanteeData, dataIndex) => {
        // Check if data with the same passengerIndex already exists
        const existingIndexGara = garaList.findIndex(entry => entry.passengerIndex === dataIndex);

        if (existingIndexGara !== -1) {
            // If the same passengerIndex exists, override the existing entry
            const updatedGaraList = [...garaList];
            updatedGaraList[existingIndexGara] = { ...garanteeData, dataIndex };
            setGaraList(updatedGaraList);
        } else {
            // If the passengerIndex doesn't exist, add a new entry to the list
            setGaraList(prevList => [...prevList, { ...garanteeData, dataIndex }]);
        }
    };


    set_to_local(selectedBaggagePerPassenger, 'baggage')
    set_to_local(returnres, 'seat_map')
    set_to_local(index, 'quick_option')
    set_to_local(selectedClass2, 'ticket_type')
    set_to_local(selectedClass, 'ticket_type_price')

    const day2 = localStorage.getItem('day2');
    const day = localStorage.getItem('day');
    const departure = localStorage.getItem('departure');
    const fromCountry = localStorage.getItem('fromCountry');
    const fromCode = localStorage.getItem('fromCode');
    const toCode = localStorage.getItem('toCode');
    const toCountry = localStorage.getItem('toCountry');
    const arrival = localStorage.getItem('arrival');
    const stops = localStorage.getItem('stops');
    const currentYear = new Date().getFullYear();

    const subText1 = [
        'Jaromtravels.com will cover the new cost of a new trip or offer an instant refund.',
        'Get a list of alternative flights - including options from other carriers.',
        ' Choose your replacement flight instantly without the need to contact anyone.',
    ];
    const subText2 = [
        'You can choose an alternative trip, but we won’t cover the cost. Or you can request a refund from the carriers through us, but the amount and the speed of the refund will depend on carrier policy and relevant laws. On average, it takes us around 7 days to forward any refunds.',
    ];


    return(
        <div className='useless'>
        <div className='oga_o'>
            <div className='Maincontainer'>
                <div className='fullcont'>

                    <div className="idontKnow">

                        <div className='travellerInfo'>
                            <div className="progressTrackerCont">
                                {page === 2 && <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1689862368/starpenzu/ul_iau104.svg" alt="" className="progressTracker"/>}
                                {page === 3 && <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691356471/starpenzu/custm_xtglkc.svg" alt="" className="progressTracker"/>}
                                {page === 4 && <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691356471/starpenzu/custm_xtglkc.svg" alt="" className="progressTracker"/>}
                            </div>

                            {page === 4 ?
                                <div className="tesst">
                                    <div className="greenNotice">
                                        <div className="maintext">You are almost there. Make payment and avoid price changes</div>
                                    </div>
                                </div>

                                :

                                <div className="greenNotice">
                                    <div className="maintext">
                                        Congratulations! You have picked one of our best value flights. &nbsp;

                                        <span className="greenNoticeSpan">
                                              Book now so you don’t miss out on this price.
                                         </span>
                                    </div>

                                </div>

                            }



                        </div>

                        <div className="yourTripcont" >
                            <div className="yourTrip" style={{display:page === 3 ? 'block': '', paddingLeft:page === 3 ? '4px': ''}}>Your trip to {myRoute[myRoute.length - 1].cityTo}</div>
                        </div>
                    </div>



                    <div className="main_cont">



                        <div className="bookinsummNdflightItinerary"  >

                            <aside className={`bookingSumNdSupport ${page === 3 ? 'width-adj' : ''}`} >

                                <div className="bookingSum">
                                    <div className="bookingheader">
                                        Booking Summary
                                    </div>

                                    <div className="paymentSumCont">
                                        <div className="paymentSum">
                                            <div className="sumdest"><span className='sumdestBold'>Depart</span> . {day}, {currentYear} . {stops} {stops === 1 ? 'stop' : 'stops'}</div>

                                            <div className="tripsum">
                                                <div className="firstFlex">
                                                    <div className="timeCode">{departure} <span className="timecode2"> ({fromCode})</span> </div>
                                                    <div className="airportName">{fromCountry}</div>
                                                </div>
                                                <div className="secFlex">
                                                    <hr className='myhrline'></hr>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                    <hr className='myhrline'></hr>
                                                </div>
                                                <div className="thirdFlex">
                                                    <div className="timeCode">{arrival} <span className="timecode2">({toCode})</span> </div>
                                                    <div className="airportName">{toCountry}</div>
                                                    <div className="SumArrive">
                                                        Arrives on {day2}, {currentYear}.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <hr className='myhrline'></hr>
                                    </div>


                                    <div className="bookingTextCont">
                                        <div className="bookingText">
                                            Flight Base Fare
                                        </div>

                                        {adultCount > 0   && <div className="bookingSubText priceTitle">Adults x {adultCount}</div>}
                                        {childCount > 0   && <div className="bookingSubText priceTitle" style={{display: childCount === 0 ? 'None' : ''}}>Children x {childCount}</div>}
                                        {infantCount > 0 && <div className="bookingSubText priceTitle" style={{display: infantCount === 0 ? 'None' : ''}}>Infant x {infantCount}</div>}


                                        <div className="titleNdPrice">
                                            <div className="priceTitle">Base Fare</div>
                                            <div className="priceFare">{myPrice}</div>
                                        </div>

                                        {selectedBaggagePerPassenger.map((selectedBaggage, passengerIndex) => (
                                            <div key={passengerIndex} className="selectedBaggageList">
                                                {selectedBaggage.map((BaggageInfo, index) => (
                                                    <div className="titleNdPrice" key={index}>
                                                        <div className="priceTitle"> {BaggageInfo.item} <span className="makesmall">(Passenger {passengerIndex + 1})</span></div>
                                                        <div className="priceFare">
                                                            <span className="naira"></span>
                                                            {BaggageInfo.priceBag}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}

                                        {selectedClass  ?
                                            <div className="titleNdPrice">
                                                <div className="priceTitle">{selectedClass2}</div>
                                                <div className="priceFare"><span className="naira"></span>{selectedClass}</div>
                                            </div>
                                            :
                                           ''
                                        }
                                        <div style={{cursor: 'pointer', marginTop: '6px', marginBottom: '0px', paddingBottom:'0px'}}>
                                            {returnres &&
                                                (
                                                    <div className="bookingText" style={{marginBottom: '0px'}} onClick={handleSeatSumDropDown}>
                                                    Seating Summary
                                                </div>
                                                )
                                            }

                                            {Dropdown ?
                                                <div>
                                                    <div>
                                                        {returnres !== null ? (
                                                            <div>
                                                                <div>
                                                                    {returnres.map((seat, i) => (
                                                                        <div>
                                                                            <div style={{paddingLeft: '8px', paddingTop: '0px'}} className="titleNdPrice" key={i}>
                                                                                <div className="priceTitle">
                                                                                    1x Seating ({seat.cityCodeFrom !== null ? seat.cityCodeFrom : 'e'} - {seat.cityCodeTo != null ? seat.cityCodeTo : 'e'}) {seat.passengerIndex}
                                                                                </div>
                                                                                <div>
                                                                                    <div className="priceFare">
                                                                                        <div>{seat.price}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    ))}

                                                                </div>
                                                                <div>
                                                                    {index !== [] ?
                                                                        <div>
                                                                            {index.map((s, sindex)=>(
                                                                                <div style={{paddingLeft: '8px', paddingTop: '0px'}} className="titleNdPrice" key={sindex}>
                                                                                    <div className="priceTitle">
                                                                                        1x Seating ({s.cityCodeFrom !== null ? s.cityCodeFrom : 'e'} - {s.cityCodeTo != null ? s.cityCodeTo : 'e'}) {s.passengerIndex}
                                                                                    </div>
                                                                                    <div>
                                                                                        <div className="priceFare">
                                                                                            <div>{s.price}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>:
                                                                        ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : <div className='priceFare'>None</div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                ''
                                            }
                                        </div>



                                        <div>
                                            {dataList.map((insue, insurIndex)=>(
                                                <div key={insurIndex}  className="titleNdPrice">
                                                    <div className="priceTitle">{insue.travelPlan}</div>
                                                    <div className="priceFare"> {insue.price ? insue.price : '0' }</div>
                                                </div>
                                            ))}
                                        </div>

                                        <div>
                                            {garaList.map((gara, garaIndex)=>(
                                                <div key={garaIndex}  className="titleNdPrice">
                                                    <div className="priceTitle">{gara.garanteeName}</div>
                                                    <div className="priceFare"> {gara.price}</div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="titleNdPrice">
                                            <div className="priceTitle">Service Charge</div>
                                            <div className="priceFare"> {servicePriceToDisplay}</div>
                                        </div>



                                        {discount &&
                                            <div className="titleNdPrice">
                                                <div className="priceTitle">Discount</div>
                                                <div className="priceFare">{currSign}0</div>
                                            </div>
                                        }


                                        <div className="titleNdPrice">
                                            <div className="priceTitle boldit">Total Fare</div>
                                            <div className="priceFare"><span className="naira"></span>{myPrice}</div>
                                        </div>
                                        <hr className='myhrline'></hr>
                                        <div className="titleNdPrice">
                                            <div className="priceTitle unique1">Total:</div>
                                            <div className="priceFare unique2"><span className="naira"></span>{currSign} {stringMainPrice}</div>
                                        </div>
                                    </div>




                                    <div className="readNotice">
                                        <span className="redNoticeImg"><img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1689865750/starpenzu/Frame_gimj6r.svg" alt=""/></span>
                                        This price may increase if you book later.
                                    </div>

                                </div>

                                <div className="SupportContainer">
                                    <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1689865752/starpenzu/booking-help.png_avkcw3.svg" alt="" className="supportImg"/>
                                    <div className="supportText">
                                        <div className="supportHeader">For customer support</div>
                                        <div className="supportSubText">Please call <span className="bolden"> 123 456 789 </span>
                                            24/7 (Monday to Sunday).
                                        </div>
                                    </div>

                                </div>
                            </aside>



                            {page === 2 ?
                                <main className="flightItineraryContainer">

                                        <div className="moreInfoContainer2">
                                            <div className="headingItine">
                                                <div className="itineSText">
                                                    Flight Itinerary
                                                </div>
                                            </div>

                                            {bookingLoading ?
                                                <div><MoreInfoSkeleton cardNum={10}/> </div>
                                                :
                                                <MoreInfo
                                                    duration={myDuration}
                                                    route={myRoute}
                                                    style={styles}
                                                    style2={styles2}
                                                    key={myKey}
                                                />
                                            }

                                        </div>



                                        <form onSubmit={NextFunc}  className="formContainer">

                                            <div className="garantt">
                                                <div className="whoIsTravText">
                                                    Guaranteed Flight
                                                    <br/>
                                                </div>
                                                <div>
                                                    {guarantee.map((gar, i) => (
                                                        <GaranteeUI
                                                            name='GaranteeUI'
                                                            value={gar.id === 'dummy' ? 'No thanks, I’ll take the risk' : 'I want disruption protection'}
                                                            subText={gar.id === 'dummy' ? subText2 : subText1}
                                                            co={gar.id === 'dummy'}
                                                            header={gar.id === 'dummy' ? 'No thanks, I’ll take the risk' : 'I want disruption protection'}
                                                            price={gar.id === 'dummy' ? '0' : gar.price.amount}
                                                            key={i}
                                                            accumulateDatafromGarantee={accumulateDatafromGarantee}
                                                        />
                                                    ))}
                                                </div>

                                            </div>
                                            <div className="pheww">
                                                <div className="whoIsTrav">
                                                    <div className="whoIsTravText">
                                                        Who's travelling?
                                                    </div>

                                                    <div className="whoIsTravSecCont">
                                                        <div className="whoIsTravSecContText">
                                                            Your personal data is protected.
                                                        </div>

                                                        <div className="whoIsTravSecContImg">
                                                            <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1690182244/starpenzu/ssl-security-lock.png_fl3fgs.png" alt=""/>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="blueNotice">
                                                    <div className="blueNoticeText">
                                                        Use all given names and surnames exactly as they appear on your passport/ID to avoid complications.
                                                    </div>
                                                </div>



                                            </div>
                                            { passengerData.map((passenger,index) =>(
                                                <div key={index}>
                                                    <DuplicatePassengers
                                                        loading={bookingLoading}
                                                        isSelectedFirst={itemName => isBaggageSelected(itemName, index)}
                                                        onDeselectFirst={baggageInfo => handleBaggageDeselection(baggageInfo, index)}
                                                        onSelectFirst={baggageInfo => handleBaggageSelection(baggageInfo, index)}
                                                        cabinHandBaggagePriceDetails={cabinHandBaggageDetails}
                                                        cabinHandBaggagePrice={cabinHandBaggage}
                                                        cabinBaggagePrice={cabinBaggage}
                                                        cabinBaggagePriceDetails={cabinBaggageDetails}
                                                        countries={countries} gender={gender}
                                                        passangerIndex={index}
                                                        index={index}
                                                        insurance={insurance}
                                                        age={passenger.name}
                                                        status={passenger.state}
                                                        setCurrrConv={setCurrrConv}
                                                        accumulateDatafrominsure={accumulateDatafrominsure}
                                                        // onSelectFirst={handleBaggageSelection}
                                                    />
                                                </div>
                                            ))}
                                            <div className="termsCond">
                                                <div className="termsCondCont">
                                                    <input id='checkedTerms' type="checkbox" required={true} checked={termAndCondition} onChange={handleCheckBoxesChange}/>
                                                    <label htmlFor="checkedTerms">By proceeding, I acknowledge that i have
                                                        read and agree to jaromtravels.com Flight booking <span className="termsSpan">terms & conditions</span></label>
                                                </div>

                                                <div className='visa_casing'>
                                                    <button className='visa_next'  type='submit' disabled>
                                                        Continue<span><img
                                                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                                    </button>

                                                    {page === 2 ?

                                                        <Link to='/SearchResult'>
                                                            <button className='visa_next visaCent'  onClick={scrollToTop} id='timiButton'>
                                                                <img id='visaPrev'
                                                                     src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Back</span>
                                                            </button>
                                                        </Link>
                                                        :
                                                        <button className='visa_next visaCent' onClick={PrevFunc} id='timiButton' >
                                                            <img id='visaPrev'
                                                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Back</span>
                                                        </button>

                                                    }

                                                </div>
                                            </div>


                                        </form>


                                </main> : ''
                            }

                            {page === 3 ?
                                <main className='flightCustomisation'>
                                       <div className="fightCusHeaderCont1">

                                           <div className="fightCusHeaderCont2">
                                               <div className="fightCusHeaderHead">Trip Customization</div>
                                           </div>
                                       </div>

                                       <div className="cancelTrip">Get the option to change or cancel your trip</div>
                                       <div className="cancelTripText">
                                           Upgrade your ticket so you can rebook or get a
                                           refund if you decide to change your plans.
                                       </div>

                                       <div className="saveStanFlexi">
                                           {/*<button onClick={PrevFunc}>To go back</button>*/}
                                           {finalTic.map((ticket, tikIndes) =>(
                                               <div key={tikIndes}>
                                                   <MainContSSF
                                                       onSelect={handleClassSelect}
                                                       onSelect2={handleClassSelect2}
                                                       classInfo={ticket}
                                                       isSelected={isSelected(selectedClass2, ticket)}
                                                       boolVal={false}
                                                   />

                                               </div>
                                           ))
                                           }



                                       </div>


                                       <div className="EcoBus">
                                           <div className="Eco">
                                               <SeatClass
                                                   ecoSeatImg="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691435557/starpenzu/Economy_Seats_rl4ozw.svg"
                                                   EcoHeadText='Economy'
                                                   TrFa={true}
                                                   ecoSubText='Rest and recharge during your flight with extended leg room, personalized service, and a multi-course meal service'
                                                   classList={EconomyClass}
                                                   lineColour='bussLine'
                                                   listImg="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691435557/starpenzu/point_heavy_oiqszh.svg"
                                               />
                                           </div>

                                           <div className="buis">
                                               <SeatClass
                                                   ecoSeatImg="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691435557/starpenzu/Business_Seats_u3uxvc.svg"
                                                   EcoHeadText='Business class'
                                                   TrFa={false}
                                                   ecoSubText='Rest and recharge during your flight with extended leg room, personalized service, and a multi-course meal service'
                                                   classList={businessClass}
                                                   lineColour='makeGreen'
                                                   listImg="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691364280/starpenzu/mdi_tick_kdxibi.svg"
                                               />
                                           </div>
                                       </div>

                                        <div className="planeSeatcont">
                                            <div>
                                                                {status_ !== "complete" && ( <div>{ seatPage === 0 ?
                                                                    <div>
                                                                        {mainRoute.map((route, offersRoute)=>(
                                                                            <div>
                                                                                <div key={offersRoute}>
                                                                                    <FlightSeatArrg
                                                                                        handle_return={handle_return}
                                                                                        remove_return={remove_return}
                                                                                        generateSeatingSummary={generateSeatingSummary}
                                                                                        genarateQuickOptionSummary={genarateQuickOptionSummary}
                                                                                        offers={planeMap.seating.offers[offersRoute] !== undefined ? planeMap.seating.offers[offersRoute] : '' }
                                                                                        offersIndex={offersRoute}
                                                                                        route={route}
                                                                                        mainRoute={mainRoute}
                                                                                        passengerData={passengerData}
                                                                                        passengerName={PassengerNameForSeat}
                                                                                        passIndex={reciveFromEcon}
                                                                                        // passengerIndex={index}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                        }

                                                                    </div>  :
                                                                    ''
                                                                }

                                                                    {seatPage === 1 ?
                                                                        <div>
                                                                            <QuickOption/>
                                                                        </div>
                                                                        :
                                                                        ''
                                                                    }


                                                                    {seatPage === 2 ?
                                                                        <div>
                                                                            <FlightSeatArrg />
                                                                        </div>
                                                                        :
                                                                        ''
                                                                    }</div> )}

                                            </div>
                                            <div className="contBtn">
                                                <div className='visa_casing'>
                                                    <button className='visa_next' onClick={NextSeatPage} type='submit'>
                                                        Proceed to Payment<span><img
                                                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                                    </button>
                                                    <button className='visa_next visaCent' onClick={PrevSeatPage} id='timiButton' >
                                                        <img id='visaPrev'
                                                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Back</span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                </main>

                                :
                                ''
                            }

                            {page === 4 ?
                                <main>
                                    <PaystackComponent
                                        currSign={currSign}
                                        stringMainPrice={stringMainPrice}
                                        PrevSeatPage={PrevSeatPage}
                                    />
                                </main>
                                :
                                ''
                            }
                        </div>


                    </div>
                </div>
                </div>
                </div>
                </div>
    )
}

