import React from "react";
import Header from "../navbar";
import Hero from "./Hero";
import AboutMain from "./AboutMain";
import Footer from "../Home/footer";
import "./Aboutus.css"
import MyFooter from "../myFooter/MyFooter";
//import { LazyLoadImage } from "react-lazy-load-image-component";

export default function AboutUs (){


    return(
        <div className='forceMiddle'>
            <div>

                    <div className="hero-container1">
                        <div className="hero-text">
                            <h1>About Us</h1>
                            <p>Efficiency is what we are known for...You are our priority</p>
                        </div>
                    </div>






                <AboutMain/>
            </div>


            {/*<div className='forceMiddle'>*/}
            {/*    <MyFooter/>*/}
            {/*</div>*/}

        </div>



    );
}



{/*<Hero*/}
{/*    heroImgSm={"https://res.cloudinary.com/do5wu6ikf/image/upload/v1682767112/about_us_sm_kwbggr.svg"}*/}
{/*    heroImgLg={"https://res.cloudinary.com/do5wu6ikf/image/upload/v1682764636/about_us_xocrgo.svg"}*/}
{/*    headtext={"About Us"}*/}
{/*    subtext={"Efficiency is what we are known for...You are our priority"}*/}
{/*/>*/}