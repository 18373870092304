import React, {useState} from "react";
import './ourservices.css'


export default function OurServicesComp({mainText1Half, subText, imgClass, makeRight, mainText2Half, fullText }){
    const[fullContent, setFullContent] = useState(false)

    const tooglefunc = ()=>{
        setFullContent(!fullContent)
    }

    return(
        <div className="servCompcont">
            <div className="servComp">
                <div className={imgClass} id={makeRight}>
                    <div className="servCompHeader">{mainText1Half} <br/> {mainText2Half}</div>
                    <div className="servCompSubText">
                        {fullContent? fullText : subText} <span className='makrReadBold' onClick={tooglefunc}>{fullContent ? '...Read Less' : '...Read More'}</span>
                    </div>
                    {fullContent ?
                        <div className="buttonServ">
                            <div className="btnTextos">Get Started</div>
                            <div className="btnArros"><img src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg" alt=""/></div>
                        </div>
                        :
                        ''
                    }

                </div>
            </div>

        </div>
    )
}