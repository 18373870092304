import React from "react";
import Skeleton from "react-loading-skeleton";
import ManagebookingSkeleton from "../SkeletonLoadingComponent/ManageBookingSkeleton/ManagebookingSkeleton";


export default function  ManageBookingProps(props){
    return(
        <>
            {props.loading ?
                <div >
                    <ManagebookingSkeleton/>
                </div>
                :
                <div className="propsContainer">
                    <img src={props.img} alt="" className='propsImg'/>
                    <div className="propsHeaderText">
                        {props.propsHeaderText}
                    </div>
                    <div className="propsMainText">
                        {props.propsMainText}
                    </div>

                    <div onClick={props.onclick} className="propsBtn">Click Here</div>
                </div>

            }
        </>
    )
}