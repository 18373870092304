import React, {useEffect, useState} from "react";
import "./footer.css"
import ContactUsButton from "../AboutUs/ContactUs_button";
import {LazyLoadImage} from "react-lazy-load-image-component";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../dev";


export default function MyFooter() {
    const [subscriberEmail, setSubscriberEmail] = useState('')
    const [errmsg, setErrmsg] = useState(null)
    const [payLoading1, setPayLoading1] = useState(false)

    function handleChange(event) {
        setSubscriberEmail(event.target.value)
    }

    const apikey = envVariables.APIKEY;

    const handleSubmit = async (event) =>{
        event.preventDefault() //to remove the url log
        setPayLoading1(true)


        const email = subscriberEmail;

        try {
            const requestData = {
               email
            };
            const response = await axiosWithAuth(apikey).post('/user/subscribe-email/', requestData);
            if (response.status === 200 || response.status === 201) {
                // setPayLoading1(false)
                // setErrImg('https://res.cloudinary.com/do5wu6ikf/image/upload/v1702572525/starpenzu/Check_g_otgwce.svg');
                setErrmsg('successful')
                // tokenizeApi()
                console.error('Success');
                setPayLoading1(false)

            }
        } catch (error) {
            // setErrImg('https://res.cloudinary.com/do5wu6ikf/image/upload/v1702572525/starpenzu/Check_r_naosbf.svg')
            setErrmsg('Error')
            setPayLoading1(false);
            console.error('Error fetching data:', error);
        }
    }

    setInterval(() => setErrmsg(null), 2000)


    return (
        <footer>
            <div className='sFooter'>

                <div className="subFooter">

                    <div className="subFooterText">
                        <h1> Subscribe to Newsletter</h1>
                        <p> Sign up to receive exclusive discount and special offers</p>
                    </div>

                    <form onSubmit={handleSubmit} className='subForm'>
                        <div>
                            <input
                                value={subscriberEmail}
                                onChange={handleChange}
                                className="subInput"
                                name="Email" type="email" placeholder="Email"
                            /></div>

                        <ContactUsButton
                            //onClick={handleSubmit}
                            className='subButton'
                            btn_Name={payLoading1 ? 'Loading..':"Subscribe Now"}
                            type={"submit"}
                            errmsg={errmsg}
                        />
                    </form>

                </div>
                <div className="footerFix">
                    <div>
                        <div className="footerMain">

                            <div className="leftSeg">
                                <div className="footerLogo">
                                    <LazyLoadImage effect="blur" className='logo'
                                                   src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1683163940/nakedjarome_1_xfopye.svg"/>
                                </div>

                                <div className="footerText">
                                    Jarom Travels Agency Limited has grown into a full-fledged travel company providing
                                    top-notch services such as Corporate,
                                    Business, & Group Travel, Flight Ticket, Cruises, Visa applications and Processes, Studies
                                    Abroad, Airport Pickups, Hotel
                                    reservations, Special Event Planning, All-Inclusive Resort Stays, Destination Weddings &
                                    Honeymoons, Vacations, and Tours
                                    etcetera
                                </div>

                                <div className="connectFooter">
                                    Connect with us:
                                </div>

                                <div className="footerSocials">
                                    <img src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1681170452/Vector_jokqvs.svg"/>
                                    <img
                                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1681170452/Vector_1_ttb2wa.svg'/>
                                    <img
                                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1681170452/Group_1_vrhr74.svg'/>
                                    <img src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1681170452/Group_azetxw.svg'/>
                                </div>
                            </div>

                            <div className="middleSeg">

                                <div className="services">
                                    <h1>Services</h1>

                                    <ul>
                                        <li>Visa Application</li>
                                        <li>Flight Ticket</li>
                                        <li>Study Abroad</li>
                                        <li>Tours and Vacation</li>
                                        <li>Birth Services</li>
                                        <li>Honeymoon Packages</li>
                                        <li>Airport Pickup</li>
                                        <li>Permanent Residency</li>
                                        <li>GRE/IELTS/TOEFL/
                                            PTE Exams
                                        </li>
                                    </ul>

                                </div>


                                <div className="aboutUsFooter">
                                    <h1>About Us</h1>

                                    <ul>
                                        <li>About</li>
                                        <li>Terms of service</li>
                                        <li>Privacy Policy</li>
                                        <li>Cookies Policy</li>
                                        <li>FAQs</li>
                                    </ul>

                                </div>

                                <div className="contactUsFooter">
                                    <h1>Contact Us</h1>

                                    <ul>
                                        <li><h2>Tel:</h2></li>
                                        <li><p>+234 (0)90 9562 8888</p></li>
                                        <li><h2>Email:</h2></li>
                                        <li><p>contact@Jaromtravels.com</p></li>
                                        <li><h2>Office Address:</h2></li>
                                        <li><p>Novare Shared office, Zone 5 Dalaba St. Wuse.</p></li>
                                    </ul>

                                </div>
                            </div>

                            <div className="rightSeg">
                                <div className="mission">
                                    <h1>Mission</h1>
                                    <p>
                                        Our mission is to become the first-choice travel Agency for institutions and clients
                                        around
                                        the world, adding more valuable services to customers in the Travel industry
                                    </p>

                                </div>

                                <div className="vision">
                                    <h1>Vision</h1>
                                    <p>
                                        Integrating travel agencies to NEXT LEVEL to achieve maximum growth and satisfy our
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyRight">
                    <p>&copy; Copyright 2023. All Rights Reserved</p>
                </div>

            </div>
        </footer>
    )
}