import React, {useState}from "react";
import InputField from "../InputField/InputField";
import UserImg from "../../images/user.png"
import Email from "../../images/sms.png"
import ContactUsButton from "../AboutUs/ContactUs_button";
import SupportFormSkeleton from "../SkeletonLoadingComponent/AboutSupportSkeleton/SupportForm";



export default function SupportUsForm({loading}){

    const [data, setData] = useState(
        //this is to retrieve the inputted data from the browser local storage
        () => JSON.parse(localStorage.getItem("SupportData")) || { fullName : "", Email : "", Message: "" }
    )

    React.useEffect(
        function (){
            return(
                //creating a form of localstorage for the app on the browser
                localStorage.setItem("SupportData", JSON.stringify(data))
                //clean up function
            )
        },
        [data] // will run the function if anything in notes arr changes
    )

    function handleChange(event) {

        const {name, value} = event.target;
        // setting your new state
        setData(prevState => (
            {
                ...prevState,
                [name]: value
            }
        ))
        // console.log(event.target)
    }
  // console.log(data)

    function handleSubmit(event) {
        event.preventDefault() //to remove the url log
        alert("Accepted!")

    }

    return(
        <div className='formData'>
            <div className="sendUsMessageText">Send us a message and get a reply ASAP.</div>
            <form className="textInputField" onSubmit={handleSubmit}>

                {loading ?
                    <SupportFormSkeleton/>
                    :
                    <div className="iconInput">
                        <span className="icon"><img src={UserImg} alt="user"/></span>

                        <input
                            value={data.fullName}
                            onChange={handleChange}
                            className="FullNameInput cruise"
                            name="fullName" type="Text" placeholder="Full Name"
                            required={true}
                        />

                    </div>
                }


                {loading ?
                    <SupportFormSkeleton/>
                    :
                    <div className="iconInput">
                        <span className="icon"><img src={Email} alt="email"/></span>

                        <input
                            value={data.Email}
                            onChange={handleChange}
                            className="Email cruise"
                            name="Email" type="email" placeholder="Email"
                            required={true}
                        />

                    </div>
                }

                {loading ?
                    <SupportFormSkeleton textarea={true}/>
                    :
                    <textarea
                        name="Message" rows={4} cols={40}
                        value={data.Message}
                        onChange={handleChange}
                        className="textareaInput dodo"
                        placeholder="Type a message..."
                        required
                    />
                }


                {/*<input*/}
                {/*    value={data.Message}*/}
                {/*    onChange={handleChange}*/}
                {/*    className="textareaInput dodo"*/}
                {/*    name="Message" type="textarea" placeholder="Send us message"*/}
                {/*    required*/}
                {/*/>*/}

                <ContactUsButton
                    className='SupportButton'
                    btn_Name="Send Message"
                    type={"submit"}
                />
            </form>
        </div>
    )
}