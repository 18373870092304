import React, { useState, useEffect } from 'react';

const CalendarButton = ({ image, text, storageKey, storageKey2, className, onClick, idBorder }) => {
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedDate2, setSelectedDate2] = useState('');

    const looseIt = () => {
        onClick();
        dataChange();
    };
    const dateReset = ()=>{
        let storedDate = localStorage.getItem(storageKey);
        let storedDate2 = localStorage.getItem(storageKey2);
        const today = new Date();
        if (storedDate < today) {
            storedDate= today;
            localStorage.setItem(storageKey, storedDate);
        }
        if (storedDate2 < today) {
            storedDate2 = today;
            localStorage.setItem(storageKey2, storedDate2);
        }
    }
    //

    const dataChange = () => {
        const storedDate = localStorage.getItem(storageKey);
        const storedDate2 = localStorage.getItem(storageKey2);
        if (storedDate && storedDate2) {
            let date = new Date(storedDate);
            let date2 = new Date(storedDate2);

            const formattedDate = formatDate(date);
            const formattedDate2 = formatDate(date2);
            setSelectedDate(formattedDate);
            setSelectedDate2(formattedDate2);
        } else {
            const defaultDate = new Date();
            const formattedDefaultDate = formatDate(defaultDate);
            setSelectedDate(formattedDefaultDate);
            setSelectedDate2(formattedDefaultDate);
            localStorage.setItem(storageKey, defaultDate.toISOString().split('T')[0]);
        }
    };

    useEffect(() => {
        dataChange();
        dateReset();
    }, []);

    const formatDate = (date) => {
        return Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).format(date);
    };

    return (
        <div className='dateFull' onClick={looseIt}>
            <div className="calendar-button" id={idBorder} >
                <img src={image} alt="calendar icon" />
                <span className='setFont'>{text}<br /><span className='fixBreak'>{selectedDate} - {selectedDate2}</span></span>
            </div>
        </div>
    );
};

export default CalendarButton;
