import React from 'react';
import {Link} from "react-router-dom";
import {encrypt} from "./Security";
import TicketTabSkeleton from "./SkeletonLoadingComponent/HomeSkeleton/ticketTabSkeleton";

const TicketTab = ({ className, id,image, location, subText, dates, duration, route, token, check, arrival, toCode, departure, fromCode, key, day2, day, stops }) => {
    let price = Number(subText).toLocaleString('en-NG', { maximumFractionDigits: 0 });
    function setLocal(){
        localStorage.setItem('duration', duration);
        localStorage.setItem('route', JSON.stringify(route));
        localStorage.setItem('day2', day2);
        localStorage.setItem('day', day);
        localStorage.setItem('departure', departure);
        localStorage.setItem('fromCountry', 'Murtala Muhammed International');
        localStorage.setItem('fromCode', fromCode);
        localStorage.setItem('toCode', toCode);
        localStorage.setItem('toCountry', 'Nnamdi Azikiwe International');
        localStorage.setItem('arrival', arrival);
        localStorage.setItem('stops', stops);
        localStorage.setItem('bookingToken', encrypt(String(token)));
    }



    return (
        <>
            {check ?
                <TicketTabSkeleton/>
                :
                <div className={className} id={id}>
                    <div className="smallex">
                        <img src={image} alt="image" />
                        <div>{location}</div>
                    </div>
                    <div className="smallex-sec">
                        <div>
                            ₦{price}<br/>
                            <span>{dates}</span>
                        </div>
                        <Link to='/bookingpage'>
                            {
                                <button onClick={setLocal}>Book Ticket</button>
                            }

                        </Link>
                    </div>
                </div>
            }
        </>

    );
};

export default TicketTab;
