import React from "react";
import './ourservices.css'
import OurServicesComp from "./OurServicesComp";


export default function ourservices(){
    return(
        <div className='forceMiddle'>
           <div className="oursercont">
               <div className="ourserheadercont">
                   <div className="ourserheader">Our Services</div>
                   <div className="ourserheadertext">
                       We are committed to giving a quality services for  you.<br/>
                       As we deliver on time and give you a  comfortable, affordable,<br/>
                       yet high quality service that suits you best.
                   </div>
               </div>

               <div>
                   <OurServicesComp
                       imgClass='servBg'
                   mainText1Half='IELTS Training'
                   mainText2Half='& Registration'
                    fullText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    '
                   subText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis'
                   />


                   <OurServicesComp
                       imgClass='servBg2'
                       mainText1Half='Touring'
                       mainText2Half='Package'
                       makeRight='makeRight'
                       fullText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    '
                       subText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis'
                   />

                   <OurServicesComp
                       imgClass='servBg3'
                       mainText1Half='Work While'
                       mainText2Half='Studying'
                       fullText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    '
                       subText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis'
                   />

                   <OurServicesComp
                       imgClass='servBg4'
                       mainText1Half='Visa'
                       mainText2Half='Processing'
                       makeRight='makeRight'
                       fullText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    '
                       subText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis'
                   />

                   <OurServicesComp
                       imgClass='servBg5'
                       mainText1Half='Birth Service'
                       mainText2Half='Abroad'
                       fullText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    '
                       subText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis'
                   />

                   <OurServicesComp
                       imgClass='servBg6'
                       mainText1Half='Relocation'
                       mainText2Half='Service'
                       makeRight='makeRight'
                       fullText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis.
                    '
                       subText='Lorem ipsum dolor sit amet consectetur. Turpis viverra eros nulla nulla. Habitasse magnis nunc aenean sit amet egestas. Bibendum nibh elit aenean donec. Cursus sagittis sit faucibus elementum pharetra ac maecenas. Platea mauris viverra interdum malesuada integer at. Suspendisse semper pulvinar accumsan a id. Porttitor lacus purus convallis integer ornare leo id augue. Pulvinar magna erat quam turpis at lacus eu bibendum molestie. Magnis'
                   />
               </div>
           </div>
        </div>
    )
}