import React, {useState} from 'react';
import BlueBack from "../SignUp/BlueBack";
import './login.css';
import InputField from "../InputField/InputField";
import sma from "../../images/sms.png";
import user from "../../images/user.png";
import {Link} from "react-router-dom";
import axios from "axios";
import axiosWithAuth from "../Axios/axiosWithAuth";
import lock from "../../images/lock.png";
import Spinner from "../Spinner/Spinner";
import envVariables from "../../dev";

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const apikey = envVariables.APIKEY;

        try {
            const response = await axiosWithAuth(apikey).post('/user/token/', {
                email,
                password,
            });

            if (response.status === 200 || response.status === 201) {
                const token = response.data.token;
                localStorage.setItem('token', token);
                // Navigate to the next screen
                console.log(localStorage.getItem("token"))
                window.location.href = '#/';
            } else {
                setErrorMessage('Invalid credentials. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
        finally{
            setIsLoading(false)
        }
    };

    return (
        <div>
            <BlueBack containerClassName='loginBack'>
                <img id='SignUpBack-cancelButton' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png' alt='cancelButton'/>
                <div className='firstOne'>
                    Welcome to Jarom Travels
                </div>
                <div className='secOne' >Have access to prompt flight updates and<br/>special offers</div>
                <button className='socialButtons'><img alt='googleLogo' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678228461/flat-color-icons_google_j3ofzp.png'/>Sign up with Google</button>
                <button className='socialButtons'><img alt='faceBookLogo' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678228905/ci_apple_xdoib0.png'/>Sign up with FaceBook</button>
                <div className='liner'><hr/><span className='or'>or</span><hr/></div>
                <form onSubmit={handleSubmit}>
                    <InputField classNameSg='sg sgx' value={email} onChange={(event) => setEmail(event.target.value)} className="col-9 fi"
                                name="Email" type="email" placeholder="Email" required iconSrc={sma}/>
                    <InputField classNameSg='sg' className="col-9 fi" name="Password" type="password"
                                placeholder="Password" onChange={(event) => setPassword(event.target.value)} value={password}
                                required={true} iconSrc={lock}/>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                <div className='forget'>
                    <Link to="/password-reset">
                        Forget Password
                    </Link>
                </div>

                <label>
                    <input type="checkbox" className="custom-checkbox"/>
                    <span className="checkmark"></span>
                    <span className='logged'>Keep me logged in</span>
                </label>
                <button type='submit' disabled={isLoading} className='stepButton stepButtonMargin'>
                    {isLoading ? <Spinner /> : 'Login'}
                </button>
                </form>

                <div className='blueBack-fort toper'>Don't have an account? <span><Link to ="/signup">Register</Link>
                </span>. </div>
            </BlueBack>
        </div>
    );
}

