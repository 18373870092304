import React, {useEffect, useState} from "react";
import navbar from '../navbar'
import MyFooter from "../myFooter/MyFooter";
import ManageBookingProps from "./ManageBookingProps";
import FormProps from "./FormProps";
import './manageBooking.css'
import Skeleton from "react-loading-skeleton";


export default function ManageBookingPage(){
    const [form1, setForm1] = useState(false)
    const [form2, setForm2] = useState(false)
    const [form3, setForm3] = useState(false)


    const handleSubmit1 = (formdata) =>{
        console.log('submitted from 1st form')
        console.log(formdata)
    }

    const handleSubmit2 = (formdata) =>{
        console.log('submitted from 2nd form')
        console.log(formdata)

    }

    const handleSubmit3 = (formdata) =>{
        console.log('submitted from 3rd form')
        console.log(formdata)

    }

    const handleFormDisplay1 = () =>{
        if(!form1){
            setForm1(true)
        }else {
            setForm1(false)
            // setForm2(false)
            // setForm3(false)
        }

    }

    const handleFormDisplay2 = () =>{
        if(!form2){
            setForm2(true)
        }else {
            setForm2(false)
            // setForm2(false)
            // setForm3(false)
        }

    }


    const handleFormDisplay3 = () =>{
        if(!form3){
            setForm3(true)
        }else {
            setForm3(false)
            // setForm2(false)
            // setForm3(false)
        }

    }

    const [loading, setLoading]= useState(true)

    useEffect(() => {
        // Use setTimeout to change the message after 2 seconds
        const timer = setTimeout(() => {
            setLoading(false);
        }, 5000);

        // Cleanup the timer to avoid memory leaks
        return () => clearTimeout(timer);
    }, []);



    return(
        <>
            <navbar/>
            <div className="bodyContainer">
                {loading ?
                    <div className="headerText"> <Skeleton width={500} count={1}/></div>
                    :
                    <div className="headerText">What would you like to do?</div>
                }




                <div className="manageBookingWrapper">

                    <div className="withform">

                    <ManageBookingProps
                        img='https://res.cloudinary.com/do5wu6ikf/image/upload/v1688650029/starpenzu/material-symbols_airplane-ticket-outline_d3uoae.svg'

                        propsHeaderText='Get my ticket'

                        propsMainText='Yet to get your ticket?
                                    This service allows you to request for
                                    your tciket on paid booking'


                        onclick={handleFormDisplay1}

                        loading={loading}
                    />
                        {form1 &&

                            (
                               <FormProps
                                   onsubmit={ handleSubmit1}
                                   onclick={handleFormDisplay1}
                               />
                            )
                        }

                    </div>


                    <div className="withform">

                    <ManageBookingProps
                        img='https://res.cloudinary.com/do5wu6ikf/image/upload/v1688650026/starpenzu/fluent-mdl2_air-tickets_cqtumk.svg'

                        propsHeaderText='Get Refund'

                        propsMainText='This Service allows you to get a refund on your ticket of a paid booking'

                        loading={loading}

                        onclick={handleFormDisplay2}
                    />

                        {form2 &&

                            (
                                <FormProps
                                    onsubmit={handleSubmit2}
                                    onclick={handleFormDisplay2}
                                />
                            )
                        }
                    </div>

                    <div className="withform">

                    <ManageBookingProps
                        img='https://res.cloudinary.com/do5wu6ikf/image/upload/v1688650024/starpenzu/arcticons_ticketswap_hhnrid.svg'

                        propsHeaderText='Ticket Change'

                        propsMainText='Need to make changes on your ticket?
                                       This Service allows you to make changes
                                        on your ticket (date, location etc.)'

                        loading={loading}

                        onclick={handleFormDisplay3}
                    />
                        {form3 &&

                            (
                                <FormProps
                                    onclick={handleFormDisplay3}
                                    onsubmit={ handleSubmit3}

                                />
                            )
                        }
                </div>

                </div>


            </div>
            {/*<MyFooter/>*/}
        </>
    )
}