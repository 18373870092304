import React, { useState, useEffect } from 'react';

const EmploymentRadioInput = ({ text, localStorageName, onClick }) => {
    const [color, setColor] = useState('');

    useEffect(() => {
        const storedColor = localStorage.getItem(localStorageName);
        if (storedColor==='Employed') {
            setColor('blue');
        }
        else if (storedColor==='Self-Employed') {
            setColor('red');
        }
        else if (storedColor==='Unemployed') {
            setColor('black');
        }
        else if (storedColor==='others') {
            setColor('white');
        }
    }, [localStorageName]);

    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setColor(newColor);
        if (newColor==='blue') {
            localStorage.setItem(localStorageName, 'Employed');
        }
        else if (newColor==='red') {
            localStorage.setItem(localStorageName, 'Self-Employed');
        }
        else if (newColor==='black') {
            localStorage.setItem(localStorageName, 'Unemployed');
        }
        else if (newColor==='white') {
            localStorage.setItem(localStorageName, 'others');
        }


    };

    return (
        <>
            <div className='visa_choice visa_dull'>
                <input
                    type="radio"
                    name="color"
                    value="blue"
                    checked={color === "blue"}
                    onChange={handleColorChange}
                />
                <label htmlFor="blue">Employed&nbsp;&nbsp;&nbsp;&nbsp;</label>

                <input
                    type="radio"
                    name="color"
                    value="red"
                    checked={color === "red"}
                    onChange={handleColorChange}
                />
                <label htmlFor="red" id='visa_rad'>Self-employed</label>
                <input
                    type="radio"
                    name="color"
                    value="black"
                    checked={color === "black"}
                    id='visa_rad'
                    onChange={handleColorChange}
                />
                <label htmlFor="black" id='visa_rad'>Unemployed</label>

                <input
                    type="radio"
                    name="color"
                    value="white"
                    checked={color === "white"}
                    onChange={handleColorChange}
                />
                <label htmlFor="white">Others</label>
            </div>
        </>
    );
};

export default EmploymentRadioInput;
