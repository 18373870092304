import {
    HashRouter as Router, Route, Routes
} from "react-router-dom";
import Home from "./components/Home/Home";
import SignUpOne from "./components/SignUp/SignUpOne";
import React from "react";
import SignUpTwo from "./components/SignUp/SignUpTwo";
import Login from "./components/Login/Login";
import PasswordReset from "./components/PasswordPages/PasswordReset";
import PasswordResetEmail from "./components/PasswordPages/PasswordResetEmail";
import Visa from "./components/Visa/visa";
import AboutUs from "./components/AboutUs/AboutUs";
import SupportPage from "./components/Support/SupportPage";
import Header from "./components/navbar";
import SearchResult from "./components/FlightSearch/search";
import ManageBookingPage from "./components/ManageBookingPage/ManageBookingPage";
import BookingPage from "./components/Bookingpage/BookingPage";
import FlightSeatArrg from "./components/FlightSeatBooking/FlightSeatArrg";
import Testcomponent from "./components/myFooter/Testcomponent";
import Ourservices from "./components/OurServices/Ourservices"
import MyFooter from "./components/myFooter/MyFooter";



function App() {
    // const isVis  = localStorage.getItem('isVisible') ? JSON.parse(localStorage.getItem('isVisible')) : true;

  return (  // <SkeletonTheme baseColor="#e0dddd" highlightColor="#f9f6f6">
       <Router>
           <Header/>
           <Routes>
                   <Route exact  path ="/" element={<Home/>} />
                   <Route path ="/signup" element={<SignUpOne/>}/>
                   <Route path ="/signup/step" element={<SignUpTwo/>}/>
                   <Route path ="/login" element={<Login/>}/>
                   <Route path ="/password-reset-email" element={<PasswordResetEmail/>}/>
                   <Route path ="/password-reset/:uidb64/:token" element={<PasswordReset/>}/>
                   <Route path ="/visa" element={<Visa/>}/>
                   <Route path ="/AboutUs" element={<AboutUs/>}/>
                   <Route path ="/Support" element={<SupportPage/>}/>
                   <Route path ="/SearchResult" element={<SearchResult/>}/>
                   <Route path ="/managebookingpage" element={<ManageBookingPage/>}/>
                   <Route path ="/bookingpage" element={<BookingPage/>}/>
                   <Route path ="/plane" element={<FlightSeatArrg/>}/>
               <Route path ="/testcomponent" element={<Testcomponent/>}/>
               <Route path ="/ourservices" element={<Ourservices/>}/>
           </Routes>

           {/*{!isVis ?*/}
               <MyFooter/>
           {/*    :*/}
           {/*    ''*/}
           {/*}*/}



       </Router>
      // </SkeletonTheme>
  );
}


export default App;



