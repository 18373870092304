import React, {useState} from "react";
import BuisnessSeat from "./BuisnessSeat";
import EconSeat from "./EconSeat";


export default  function PlaneSeat({seating_, mainRoute, seating, checkcoloum, offersIndex, route, offers, seatgroup, passengerData, generateSeatingSummary, handle_return, passengerName, passIndex, remove_return}){
    // const [selectOne, setSelectOne] = useState(null)
    const get_from_local = (key_name) =>{
        const data_stringify = localStorage.getItem(`${key_name}`);
        if(data_stringify){
            const full_response = JSON.parse(data_stringify)
            // console.log(`${key_name},found in local storage`)
            return full_response
        }else{
            // console.log(`${key_name}, not found in local storage`)
        }
    }



    const maxNum = checkcoloum;

    const [selectedSeats, setSelectedSeats] = useState([]);


    // const maxPassengers1 = passengerData.length;

    function selectOneSeat(seatId) {
        const nameList = Object.values(get_from_local(`seatDictionary${offersIndex}`)).map(item => item.name);
        setSelectedSeats([...nameList])
        // Check if the seat is already selected
        const isSeatSelected = selectedSeats.includes(seatId);

        // console.log(selectedSeats, 'isSelected')

        if (isSeatSelected) {
            // If selected, remove it from the array
            setSelectedSeats((prevSelectedSeats) =>
                prevSelectedSeats.filter((selectedSeat) => selectedSeat !== seatId)
            );


            setSelectedSeats([...nameList])


        } else {
            // If not selected and the number of selected seats is less than the maximum
            if (selectedSeats.length < passengerData.length) {
                setSelectedSeats((prevSelectedSeats) => [...prevSelectedSeats, seatId]);
            } else {
                // Handle the case where the maximum number of seats is reached
                console.warn('Maximum number of seats reached!');
                // You can display a message, show a notification, or handle it as needed.
            }
        }
    }

    // selectOne = [...nameList];
    // selectOneSeat(seats.name);

    // console.log(passengerData, 'fg')




    return(
        <div key={offersIndex}>
                <div className="Seat1of3">
                    <div className="seating">Seating {offersIndex + 1} of {mainRoute.length}</div>
                    <div>
                        <div className='dirTrip'>{route.cityFrom} → {route.cityTo}</div>

                    </div>


                </div>

                <div className="seatStatus">

                    <div className="busExtra">
                        <div className="Bus">
                            <div className="box1"></div>
                            <div className="Box1Text">Business($100 - $150)</div>
                        </div>

                        <div className="extra">

                            <div className="box1 extra-leg">

                            </div>

                            <div className="Box1Text">Extra Legroom($21.14 - $82.10)</div>

                        </div>
                    </div>

                    <div className="EconAva">
                        <div className="Econ">
                            <div className="Box1 makeBluue"></div>
                            <div className="Box1Text">Economy($21.14 - $82.10)</div>
                        </div>

                        <div className="Ava">

                            <div className="box1 unavailableSeat">

                            </div>

                            <div className="Box1Text">Unavailable</div>

                        </div>
                    </div>

                </div>

                <div className="planeSeat">
                    <div className="MaCOnt">
                        <div className="planecont">
                            <div className="AllClassContI">
                                <div className="AllClassContII">


                                    <div className="colClass">
                                        <div className="EconClass">
                                            <div className="ExitrowCont">
                                                <div className="ExitRow">
                                                    ! Exit Row
                                                </div>
                                            </div>
                                            {(maxNum && Array.isArray(maxNum)) &&
                                                <>
                                                    {
                                                        maxNum.map((columns, index) =>(
                                                            <div key={index} className={`EconClass-Col6`}>

                                                                {
                                                                    columns.seat_groups.map((group, groupIndex)=>(
                                                                        <>
                                                                            {
                                                                                groupIndex !== columns.seat_groups.length - (columns.seat_groups.length) && (
                                                                                    <div className="makecenter">  <div className="nubBox" style={{paddingLeft: groupIndex === 0 && columns.seat_groups.length === 1? '90px' : '' }}>{`${columns.row_number}  `}</div>  </div>)
                                                                                //                 ${groupIndex} ${columns.seat_groups.length}
                                                                            }
                                                                            <div key={groupIndex} className="seatgroupCompatment">


                                                                                {
                                                                                    group.map((seat, seatIndex)=>(<>
                                                                                            <div key ={seatIndex}>
                                                                                                {seat.column !== undefined|| null ?
                                                                                                    <div>
                                                                                                        {(seat.seat_class === 'premium') &&
                                                                                                            <EconSeat
                                                                                                                passIndex={passIndex}
                                                                                                                passengerData={passengerData}
                                                                                                                passengerName={passengerName}
                                                                                                                handle_return={handle_return}
                                                                                                                remove_return={remove_return}
                                                                                                                mainRoute={mainRoute}
                                                                                                                selectOne={selectedSeats}
                                                                                                                seatMapData={offers}
                                                                                                                generateSeatingSummary={generateSeatingSummary}
                                                                                                                premium={true}
                                                                                                                grIndex={groupIndex}
                                                                                                                seatIndex={seatIndex}
                                                                                                                seatMapIndex={offersIndex}
                                                                                                                state={seat.state}
                                                                                                                selectOneSeat={selectOneSeat}
                                                                                                                extraRoom={seat.seat_class === "Extra legroom Seat" ? 'extra-leg': ''}
                                                                                                                seat={seat}
                                                                                                            />
                                                                                                        }
                                                                                                        {(seat.seat_class !== 'premium') &&
                                                                                                            <EconSeat
                                                                                                                passIndex={passIndex}
                                                                                                                passengerName={passengerName}
                                                                                                                remove_return={remove_return}
                                                                                                                handle_return={handle_return}
                                                                                                                seatMapData={offers}
                                                                                                                generateSeatingSummary={generateSeatingSummary}
                                                                                                                passengerData={passengerData}
                                                                                                                mainRoute={mainRoute}
                                                                                                                premium={false}
                                                                                                                selectOne={selectedSeats}
                                                                                                                selectOneSeat={selectOneSeat}
                                                                                                                seatMapIndex={offersIndex}
                                                                                                                seatIndex={seatIndex}
                                                                                                                state={seat.state}
                                                                                                                extraRoom={seat.seat_class === "Extra legroom Seat" ? 'extra-leg': ''} seat={seat}
                                                                                                            />
                                                                                                        }

                                                                                                    </div>
                                                                                                    :
                                                                                                    <div  style={{visibility: 'hidden'}} className='makered'><EconSeat seat={seat}/></div>
                                                                                                }


                                                                                            </div>



                                                                                            {
                                                                                                //write a logic to check number of seat here
                                                                                                // if max - group.length != 0,
                                                                                                // max - group.length, this resulting value will be the number of empty box to be added
                                                                                            }
                                                                                        </>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    ))
                                                                }

                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            }




                                        </div>

                                    </div>
                            </div>
                        </div>
                    </div>






                </div>
            </div>

        </div>
    )
}