import { useState, useEffect } from "react";
import { DateRange } from "react-date-range";
import './calender.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const Calendar = ({ storageKey, storageKey2 }) => {
    let storedStartDate = localStorage.getItem(storageKey);
    let storedEndDate = localStorage.getItem(storageKey2);
    const today = new Date();
    if (storedStartDate < today) {
        storedStartDate = today;
    }
    if ( storedEndDate < today) {
        storedEndDate = today;
    }

    const [state, setState] = useState([
        {
            startDate: storedStartDate ? new Date(storedStartDate) : new Date(),
            endDate: storedEndDate ? new Date(storedEndDate) : new Date(),
            key: 'selection'
        }
    ]);

    const formatDate1 = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        // Save the dates to local storage whenever the state changes
        const startDate = state[0].startDate;
        const endDate = state[0].endDate;
        const formattedStartDate = formatDate1(startDate);
        const formattedEndDate = endDate ? formatDate1(endDate) : null;
        localStorage.setItem(storageKey, formattedStartDate);
        localStorage.setItem(storageKey2, formattedEndDate);
    }, [state, storageKey, storageKey2]);

    const handleDateChange = (item) => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1); // Get yesterday's date
        const today = new Date();

        const selectedStartDate = item.selection.startDate;
        const selectedEndDate = item.selection.endDate;

        // Check if selected start date is earlier than yesterday
        if (selectedStartDate < yesterday) {
            // Reset the start and end dates to yesterday
            item.selection.startDate = today;
            item.selection.endDate = today;
        }

        // Check if selected end date is earlier than yesterday
        if (selectedEndDate < yesterday) {
            // Reset the end date to yesterday
            item.selection.endDate = today;
        }

        setState([item.selection]);
    };

    return (
        <div className="realCalender">
            <DateRange
                editableDateInputs={true}
                onChange={handleDateChange}
                moveRangeOnFirstSelection={false}
                ranges={state}
            />
        </div>
    );
};

export default Calendar;
