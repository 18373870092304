import React from 'react'

export default function SeatOption ({inputId, title, subtitle, name, value, seatMapIndex, passengerIndex, mainRoute, quickOption, genarateQuickOptionSummary, handle_return, quickoptionIndex}){
    const price = subtitle;



    const currency = localStorage.getItem('Currency');
    let currSign = ''
    let price_ = '';
    if (currency === 'NGN') {
        price_ = Number(price).toLocaleString('en-NG', { maximumFractionDigits: 2 });
        price_ = `₦${price_}`;
        currSign = '₦'
    }
    else if (currency === 'USD') {
        price_ = Number(price).toLocaleString('en-US', { maximumFractionDigits: 2 });
        price_ = `$${price_}`;
        currSign ='$'
    }
    else if (currency === 'EUR') {
        price_ = Number(price).toLocaleString('en-GB', { maximumFractionDigits: 2 });
        price_ = `€${price_}`;
        currSign='€'
    }


    const seatImage= {
        'window': 'https://res.cloudinary.com/do5wu6ikf/image/upload/v1692221424/starpenzu/Frame_1000004079_xqz84p.svg',
        'aisle': 'https://res.cloudinary.com/do5wu6ikf/image/upload/v1692221550/starpenzu/Frame_1000004078_xnou9d.svg',
        'extra_leg_room': 'https://res.cloudinary.com/do5wu6ikf/image/upload/v1692221702/starpenzu/Frame_1000004078_b1hl3w.svg',
        'sit_together': 'https://res.cloudinary.com/do5wu6ikf/image/upload/v1692221423/starpenzu/Frame_1000004078_pwl80k.svg'
    }
    function passimage (x){
        if(x === 'window'){
            return seatImage.window;
        }
        if(x === 'aisle'){
            return seatImage.aisle;
        }
        if(x === 'extra_leg_room'){
            return seatImage.extra_leg_room;
        }
        if(x === 'sit_together'){
            return seatImage.sit_together;
        }

    }

    const localStorageKeys_2 = [];

    function customStringify(obj) {
        const seen = new WeakSet();

        function replacer(key, value) {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return "[Circular Reference]";
                }
                seen.add(value);
            }
            return value;
        }

        return JSON.stringify(obj, replacer);
    }

    const set_to_local = (dataToStore, key_name) => {
        const data_stringify = customStringify(dataToStore);
        localStorage.setItem(key_name, data_stringify);
        localStorageKeys_2.push(key_name);
    }



    function convertToTitleCase(inputString) {
        const words = inputString.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1));
        const result = words.join(' ');

        return result;
    }

    const newTitle = convertToTitleCase(title);

    const handleRadioChange = () => {
        const quickOptionIndi = [];
        const selectedSeatsBySeatmapId = new Map();

        const dynamicSelectedSeats = [];


        function handleSeatClick(seatmapIndex, seats) {
            dynamicSelectedSeats.push({ seatmapIndex, seats });
        }


        handleSeatClick(seatMapIndex, quickOption);

            dynamicSelectedSeats.forEach((data) => {
            const { seatmapIndex, seats } = data;

            const smi = seatmapIndex;

            const sm = mainRoute[smi];
            const isSelectedRoute = smi === seatMapIndex;

            let currentSeats = selectedSeatsBySeatmapId.get(smi) || [];


            currentSeats = seats;
            selectedSeatsBySeatmapId.set(smi, currentSeats);

            const quickOptiondata = {
                seatmapid: isSelectedRoute ? smi : '',
                cityTo: isSelectedRoute ? sm.cityTo : '',
                cityFrom: isSelectedRoute ? sm.cityFrom : '',
                cityCodeTo: isSelectedRoute ? sm.cityCodeTo : '',
                cityCodeFrom: isSelectedRoute ? sm.cityCodeFrom : '',
                price:isSelectedRoute ?  price_ : '',
                passengerIndex: isSelectedRoute ? passengerIndex : '',
                intPrice: isSelectedRoute ? parseFloat(price_.replace(/[^0-9.-]/g, '')) : '',
                seat: quickOption,
            };

            if (quickOptionIndi.length > mainRoute.length) {
                console.log(quickOptionIndi.length, 'exceeded');
                console.log(mainRoute.length, 'len');
            } else {
                console.log(mainRoute.length, 'lenpass');
                quickOptionIndi.push(quickOptiondata);
            }

                const flattenedData = quickOptionIndi.reduce((acc, innerArray) => acc.concat(innerArray), []);

                flattenedData.forEach(object => {
                    // genarateQuickOptionSummary(object)
                    genarateQuickOptionSummary(object, seatMapIndex);
                    set_to_local(object, `seatmapData ${seatMapIndex}`)
                });

            // console.log(quickOptionIndi, 'holy 2')
            // set_to_local(quickOptionIndi, `seatmapData ${seatMapIndex} passenger ${passengerIndex}`)

            set_to_local(seats, `selectedQuickOption  seatMapIndex ${seatMapIndex}`)

                // console.log(summary, 'holy 2')
                // handle_return(summary, price_)


                localStorage.setItem('keytoclear2', customStringify(localStorageKeys_2))
                // console.log(localStorageKeys_2, '2')


        });

    };


    return(
        <div className="RandomSeat" key={quickoptionIndex}>
            {/*<input type="radio" name={name} id={inputId} onChange={handleRadioChange} value={value} className='RS'/>*/}
            <input type="radio" name={`map_${seatMapIndex}`} id={inputId} onChange={handleRadioChange} value={value} className='RS'/>

            <label htmlFor={inputId}>
                <div className="RSImg">
                    <img src={passimage(title)} alt="" className="RSImgMain"/>
                </div>

                <div className="titleRS">{newTitle}</div>
                <div className="titleRSPrice">{price_}</div>
            </label>
        </div>

    )
}