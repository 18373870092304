import React from "react";
import SupportMainSkeleton from "../SkeletonLoadingComponent/AboutSupportSkeleton/SupportMainSkeleton";

export default function SupportMain({loading}){
    return(
        <>
                <div className="SupportMainBody">

                    <div className="SupportMainContainer">
                        <div className="ContactUsText">
                            Reach out to us via:
                        </div>

                        <div className="ReachOutContainer">
                            {loading ?
                                <SupportMainSkeleton/>
                                :
                                <div className="phone">
                                <span className="stayPut">
                                     <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1682841150/call_bz6nq0.svg" alt="phoneicon" className="phoneIcon Icon"/>
                                <p>+234 123456789</p>
                                </span>

                                </div>

                            }

                            {loading ?
                                <SupportMainSkeleton/>
                                :
                                <div className="email">
                                <span className='stayPut'>
                                     <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1682841150/sms_u2drkj.svg" alt="phoneicon" className="emailIcon Icon"/>
                                    <p>info@jaromtravels.com</p>
                                </span>

                                </div>


                            }

                            {loading ?
                                <SupportMainSkeleton/>
                                :
                                <div className="location">
                                <span className="stayPut">
                                    <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1682841150/location_tg1b6q.svg" alt="phoneicon" className="locationIcon Icon"/>
                                <p>Abuja Novare  central office 1st floor shoprite building wuse zone 5 abuja, Nigeria</p>
                                </span>

                                </div>
                            }
                        </div>

                    </div>

                </div>

                <div className="OrText">
                    OR
                </div>
        </>

    )
}