import React, { useEffect, useState } from "react";
import Slider from "rc-slider";

export default function DoubleSeeker({ header, second, storageKey, handleSubmit }) {
    const [priceRange, setPriceRange] = useState([0, 1439]); // Initial range values in minutes

    const handlePriceRangeChange = (range) => {
        setPriceRange(range);
        localStorage.setItem(storageKey, JSON.stringify(range));
    };

    useEffect(() => {
        const storedValue = localStorage.getItem(storageKey);
        if (storedValue !== null) {
            const parsedValue = JSON.parse(storedValue);
            setPriceRange(parsedValue);
        }
    }, []);


    const handleTimeDrop = () => {
        if(storageKey === 'departureTime'){
            const dep_from = `${Math.floor(priceRange[0] / 60)
                .toString()
                .padStart(2, "0")}:${(priceRange[0] % 60)
                .toString().padStart(2, "0")}`;


            const dep_to = `${Math.floor(priceRange[1] / 60)
                .toString().padStart(2, "0")}:${(priceRange[1] % 60)
                .toString().padStart(2, "0")}` ;
            localStorage.setItem('dep_from', dep_from);
            localStorage.setItem('dep_to', dep_to);
        }
        if(storageKey === 'arrivalTime'){
            const arr_from = `${Math.floor(priceRange[0] / 60)
                .toString()
                .padStart(2, "0")}:${(priceRange[0] % 60)
                .toString().padStart(2, "0")}`;


            const arr_to = `${Math.floor(priceRange[1] / 60)
                .toString().padStart(2, "0")}:${(priceRange[1] % 60)
                .toString().padStart(2, "0")}` ;
            localStorage.setItem('arr_from', arr_from);
            localStorage.setItem('arr_to', arr_to);
        }
        handleSubmit();
    }



    return (
        <div>
            <div className="topper">{header}</div>
            <div className="first">
                {second} <span></span>
            </div>
            <Slider
                range
                min={0}
                max={1439}
                value={priceRange}
                onChange={handlePriceRangeChange}
                onAfterChange={handleTimeDrop}
            />
            <div className="sec">
                {Math.floor(priceRange[0] / 60)
                    .toString()
                    .padStart(2, "0")}
                :
                {(priceRange[0] % 60).toString().padStart(2, "0")} -{" "}
                {Math.floor(priceRange[1] / 60)
                    .toString()
                    .padStart(2, "0")}
                :
                {(priceRange[1] % 60).toString().padStart(2, "0")}
            </div>
        </div>
    );
}
