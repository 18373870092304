import React, {useState} from "react";
import sma from "../../images/sms.png";
import user from "../../images/user.png";
let formData = new FormData();



export default function FormProps({onsubmit, onclick}){



    const [bookingnumber, setBookingNumber] = useState('' )
    const [fullname, setFullName] = useState('' )
    const [email, setEmail] = useState('' )

    localStorage.setItem('manageBookingNumber',bookingnumber)
    localStorage.setItem('manageBookingUserFullName',fullname)
    localStorage.setItem('manageBookingEmail',email)





    function handleBookingNumber(event){
        setBookingNumber(event.target.value)
   }

    function handleBookingName(event){
        setFullName(event.target.value)
    }

    function handleBookingemail(event){
        setEmail(event.target.value)
    }




    const handleButtonSubmit = (event) =>{
        event.preventDefault();

        const manageBookingNumber = localStorage.getItem('manageBookingNumber')
        const manageBookingUserFullName = localStorage.getItem('manageBookingUserFullName')
        const manageBookingEmail = localStorage.getItem('manageBookingEmail')

        if(bookingnumber && fullname && email){
            formData.append("BookingNumber", manageBookingNumber);
            formData.append("FullName", manageBookingUserFullName);
            formData.append("Email", manageBookingEmail);


            onsubmit(formData)

        }else {
            return ''
        }


    }

    return(
        <div className="form">

            <div className="imgfldiv">
                <img className='presh' onClick={onclick} src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1689189163/add_gripw1.svg" alt=""/>
            </div>

            <div className="adjForm">

                <form onSubmit={handleButtonSubmit} className="mainForm">

                    <div className="mginput">
                        <img src={user} alt=""/>
                        <input type='text' placeholder='Booking Number' value={bookingnumber} onChange={handleBookingNumber} className=''/>
                    </div>


                    <div className="mginput">
                        <img src={user} alt=""/>
                        <input type='text' onChange={handleBookingName} value={fullname} placeholder='Full Name'/>
                    </div>


                    <div className="mginput">
                        <img src={sma} alt=""/>
                        <input type='email'  onChange={handleBookingemail} value={email} placeholder='Email'/>
                    </div>

                    <button>
                        Submit
                    </button>


                </form>
            </div>

        </div>
    )
}