import React, {useEffect, useState} from "react";
import Header from "../navbar";
import Hero from "../AboutUs/Hero";
import Footer from "../Home/footer";
import SupportMain from "../Support/SupportMain";
import SupportUsForm from "./SupportUsForm";
import "./SupportPage.css"
import MyFooter from "../myFooter/MyFooter";
//import { LazyLoadImage } from "react-lazy-load-image-component";


export default function SupportPage() {
    const [loading, setLoading]= useState(true)

    useEffect(() => {
        // Use setTimeout to change the message after 2 seconds
        const timer = setTimeout(() => {
            setLoading(false);
        }, 5000);

        // Cleanup the timer to avoid memory leaks
        return () => clearTimeout(timer);
    }, []);
    return(
        <div>
                <Header />



                <div className='supportPageBody'>

                        <div  className="hero-container">
                            <span className='decoy'>
                                <div className="hero-text">
                                <h1>How can we help?</h1>
                                <p>We are always here for you.</p>
                            </div>
                            </span>

                        </div>




                    <SupportMain loading={loading}/>

                    <SupportUsForm loading={loading}/>
                </div>

            <div className='forceMiddle'>
                {/*<MyFooter/>*/}
            </div>
        </div>
    )
}
                    {/*<Hero*/}
                    {/*    heroImgSm={"https://res.cloudinary.com/do5wu6ikf/image/upload/v1682800562/support_sm_thhsc9.svg"}*/}
                    {/*    heroImgLg={"https://res.cloudinary.com/do5wu6ikf/image/upload/v1682764461/support_uceckq.svg"}*/}
                    {/*    headtext={"How can we help?"}*/}
                    {/*    subtext={"We are always here for you."}*/}
                    {/*/>*/}
